import React, { useState } from "react";

const NumberInputComponent = () => {
  // State to store the value from the first input box
  const [storedValue, setStoredValue] = useState("");

  // Handle the change event for the first input box
  const handleInputChange = (event) => {
    setStoredValue(event.target.value);
  };

  // Handle the click event for the other input boxes
  const handleInputClick = (event) => {
    // Get the current value of the input (default to 1 if empty)
    let currentValue = event.target.value ? parseFloat(event.target.value) : 1;

    // Multiply the current value by the stored value
    const newValue = currentValue * storedValue;

    // Set the new multiplied value in the input box
    event.target.value = newValue;
  };

  return (
    <div className="bg-white h-screen">
      {/* First input box */}
      <input
        type="number"
        value={storedValue}
        onChange={handleInputChange}
        placeholder="Enter a number"
        className="mb-4 p-2 border"
      />

      {/* Three input boxes */}
      <div className=" gap-4">
        <input
          type="text"
          onClick={handleInputClick}
          placeholder="Click to set value"
          className="p-2 border"
        />
        <input
          type="text"
          onClick={handleInputClick}
          placeholder="Click to set value"
          className="p-2 border"
        />
        <input
          type="text"
          onClick={handleInputClick}
          placeholder="Click to set value"
          className="p-2 border"
        />
      </div>
    </div>
  );
};

export default NumberInputComponent;

import React from 'react';
import { connect } from 'react-redux';
import { betActions, userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import Sidebar from "../../components/Sidebar/Sidebar";
import { IoArrowBackOutline } from 'react-icons/io5';


class WinHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      size: 10,
    }
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),





    }

    this.props.dispatch(betActions.getWinningListByUserId(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData =
    {


      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      pageNo: 1,
      size: 200,

    }

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }


  getBatHistory = () => {
    let data = {
      "fromDate": this.state.startDate,
      "toDate": this.state.endDate,
      "gameId": this.state.sportsId,
    }

    this.props.dispatch(betActions.getWinningListByUserId(data));
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };


  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  }
  gobBack = () => {
    this.props.history.goBack();
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        "fromDate": this.state.startDate,
        "toDate": this.state.endDate,





      }
      this.props.dispatch(betActions.getWinningListByUserId(pagination));
    })

  }

  render() {

    let { bet, users } = this.props;
    let { betList, loading, betLoading, totalBet, winList } = bet;
    let { totalGame, gameList } = users;
    const today = new Date().toISOString().split('T')[0];
    return (

      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className='bg-white w-full h-screen'>

          <div className='h-16 w-full border bgcolor rounded-b-3xl flex justify-between items-center place-items-end px-4 gap-3'>
            <div className='flex justify-start place-items-center gap-2'>
              <IoArrowBackOutline className='text-white h-6 w-7 cursor-pointer' onClick={this.gobBack} />
              <div className='font-semibold text-white'>Win History</div>
            </div>
          </div>

          {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className='w-[90%] overflow-hidden h-full space-y-4 mx-auto'>
            <div className='py-2 space-y-2'>
              <div className=''>
                <label className='text-sm'>From Date</label>
                <input type="date" max={today} className="w-full border border-gray-400 bg-white px-3 py-1 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.startDate} onChange={this.inputChangeStartDate} />
              </div>
              <div className=''>
                <label className='text-sm'>To Date</label>
                <input type="date" max={today} className="w-full border border-gray-400 bg-white px-3 py-1 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                  name="Date"
                  dateFormat="yyyy-mm-dd"
                  id="datepicker" required
                  value={this.state.endDate} onChange={this.inputChangeEndDate}
                />
              </div>
              <div className=''>
                <label className='text-sm'>Game Name</label>
                <select onChange={this.handleChangeSelect} name="sports_id" className='w-full border border-gray-400 bg-white px-3 py-1 text-[0.8125rem] bg-transparent rounded focus:outline-none'>
                  <option value="">All</option>
                  {gameList && gameList.length > 0 ? gameList.map((element, index) => (
                    <option value={element && element._id} className='capitalize '>{element && element.name}</option>
                  )) : null}
                </select>
              </div>
              <div className='col-span-1 flex flex-col  justify-end items-end p-4'>
                <button onClick={() => this.getBatHistory()} className='py-1.5 px-4 bgblue text-white text-[0.8125rem] rounded'>Submit</button>
              </div>
            </div>

            <div style={{ height: 'calc(75vh - 100px)' }} className=" h-[60%] overflow-y-auto overflow-hidden border-2">
              <div className=' w-full mb-10 flex flex-wrap gap-5 border-2 '>
                {winList && winList.length > 0 && winList.map((element, index) => {
                  return (
                    <>
                      <div className=" mx-auto w-full bg-white shadow-lg rounded-lg overflow-hidden border border-gray-300">
                        <div className="bgcolor text-white text-center py-2">
                          <p className="text-base font-semibold">
                            {moment(new Date(parseInt(element.createdAt)))
                              .utcOffset("+05:30")
                              .format("hh:mm A")}
                            ( {element?.market?.[0] || "-"}, {element?.isOpen ? "Open" : "Close"}) </p>
                        </div>

                        <div className="p-4">
                          <div className="flex justify-center place-items-center gap-4">
                            <p>Game Name:</p>
                            <p className="text-sm capitalize">{element?.game?.[0] || "-"}</p>
                          </div>
                          <div className="mt-4 grid grid-cols-2 text-center gap-2">
                            <div>
                              <p className="text-xs text-gray-500">Point</p>
                              <p className="text-lg font-semibold textcolor">{element?.amount || "-"}</p>
                            </div>
                            <div>
                              <p className="text-xs text-gray-500">Digit</p>
                              <p className="text-lg font-semibold textcolor">{element?.betNumber || "-"}</p>
                            </div>
                          </div>

                          {/* Transaction time */}
                          <p className="text-sm text-gray-700 mt-4">
                            <span className="text-black">Transaction Time:</span><span className="text-gray-400 px-2">
                              {moment(new Date(parseInt(element.createdAt)))
                                .utcOffset("+05:30")
                                .format("DD-MM-YYYY (ddd)")}
                            </span>
                          </p>

                          {/* Winning message */}
                          <p className="text-green-600 text-center mt-4">
                            {element?.betStatus === 0 && <div className="text-yellow-500">Pending</div>}
                            {element?.betStatus === 1 && <div className="text-green-800">YOu Won Rs. +{element?.winAmount}  🤩</div>}
                            {element?.betStatus === 2 && <div className="text-red-700">Better Luck Next Time!!! 😔 -{element?.lossAmount}</div>}
                          </p>
                        </div>
                      </div>


                    </>

                  )
                })}
                {/* {

                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {
                            totalBet && totalBet > 10 ?
                              <ReactPaginate
                                previousLabel={'Prev'}
                                nextLabel={'Next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={totalBet / 10}
                                marginPagesDisplayed={5}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-cls'}
                                activeClassName={'active'}
                              />
                              : null}
                        </nav>
                      } */}


              </div>










            </div>
          </div>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet
  };
}

export default connect(mapStateToProps)(WinHistory);


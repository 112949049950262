import { notificationConstant } from "../_constants";
import { userService } from '../_services';
import { alertActions } from './';
export const notificationAction={
    getNotificationListByUserId
}

function getNotificationListByUserId(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.getNotificationListByUserId(data)

            .then(
                user => {
                    dispatch(success(user));
                
                },
                error => {

                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: notificationConstant.GET_ALL_NOTIFICATION_REQUEST, user } }
    function success(user) { return { type: notificationConstant.GET_ALL_NOTIFICATION_SUCCESS, user } }
    function failure(error) { return { type: notificationConstant.GET_ALL_NOTIFICATION_FAILURE, error } }
}
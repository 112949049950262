import { paymentConstant } from "../_constants";
import { userService } from '../_services';
import { alertActions } from './';

export const paymentAction = {
    getClientPaymentList
}

function getClientPaymentList(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.getClientPaymentList(data)

            .then(
                user => {
                    dispatch(success(user));
                
                },
                error => {

                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: paymentConstant.GET_PAYMENT_LIST_REQUEST, user } }
    function success(user) { return { type: paymentConstant.GET_PAYMENT_LIST_SUCCESS, user } }
    function failure(error) { return { type: paymentConstant.GET_PAYMENT_LIST_FAILURE, error } }
}
import React, { useEffect, useState } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { useSelector, useDispatch } from 'react-redux'
import { jackportchartAction } from '../../_actions'
import moment from 'moment'
import Loader from '../../components/Loader/Loader'

function IndianJackpotResultHiastory() {
  const selector = useSelector(state => state)
  const dispatch = useDispatch()
  const [datedata, setdatedata] = useState("");
  const currentDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    let data = {
      filterDate: datedata ? datedata : "",
    };

    dispatch(jackportchartAction.calanderForJackpot(data))
  }, [datedata]);
  const handleOnChange = (e) => {
    setdatedata(e.target.value);

  }
  const goBack = () => {
    window.history.back()
  }

  let { jackpotchart } = selector ? selector : {};
  let { getjackpotChartList, loading } = jackpotchart ? jackpotchart : {};
  let { gameResults, timingarray } = getjackpotChartList ? getjackpotChartList : {};

  const newCurrentDate = moment().format('YYYY-MM-DD'); // Get today's date in YYYY-MM-DD format
  // Sort gameResults: Place today's results first, then the rest
  const sortedResults = gameResults?.sort((a, b) => {
    const dateA = moment(a.date).format('YYYY-MM-DD');
    const dateB = moment(b.date).format('YYYY-MM-DD');

    // First prioritize today's results, then sort the rest by date
    if (dateA === newCurrentDate && dateB !== newCurrentDate) return -1;
    if (dateB === newCurrentDate && dateA !== newCurrentDate) return 1;
    return dateB > dateA ? 1 : -1; // Otherwise, sort by date in descending order
  });



  return (
    <>
      <Loader loading={loading} />
      <div className='bg-white text-black h-screen'>

        <div className='h-16 w-full border bgcolor rounded-b-3xl flex justify-start items-center px-4 gap-3'>
          <IoArrowBackOutline className='text-white w-6 h-6 cursor-pointer' onClick={goBack} />
          <div className='font-semibold text-white uppercase'>indian jackpot Result History</div>
        </div>
        <div className='flex justify-start space-x-3 items-center text-sm p-4'>
          <p>Select Date:</p>
          <input max={currentDate} type='date' onChange={(e) => handleOnChange(e)} className='bg-white px-3 py-0.5 uppercase rounded-full appearance-none border border-gray-400 text-sm' />
        </div>

        <div className="mx-auto px-4 h-screen overflow-auto pb-36">
          {sortedResults && sortedResults.length > 0 && sortedResults.map((item, index) => (
            <div key={index} className="mb-4 p-4 border rounded-md shadow-md text-xs">
              <div className="mb-2">
                <strong>Date: </strong>
                {moment(item.date).format('DD-MMM-YYYY')}
              </div>
              <div className="grid grid-cols-1">
                {item.results.map((result, idx) => (
                  <div key={idx} className="p-2 border flex justify-between rounded-md shadow-sm my-1">
                    <div>
                      <div>
                        {result?.gameTime}
                      </div>
                    </div>
                    <div>
                      {result?.jodiNumber}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* <div className="mx-auto px-4 h-screen overflow-auto pb-36">
          {gameResults && gameResults.length > 0 && gameResults?.map((item, index) => (
            <div key={index} className="mb-4 p-4 border rounded-md shadow-md text-xs">
              <div className="mb-2">
                <strong>Date: </strong>
                {moment(item.date).format('DD-MMM-YYYY')}
              </div>
              <div className="grid grid-cols-1">
                {item.results.map((result, idx) => (
                  <div key={idx} className="p-2 border flex justify-between rounded-md shadow-sm my-1">
                    <div>
                      <div>
                        {result?.gameTime}
                      </div>
                    </div>
                    <div>
                      {result?.jodiNumber}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </>
  )

}

export default IndianJackpotResultHiastory

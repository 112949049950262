import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { bankActions, userActions } from '../../_actions';
import { connect, useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline, IoCaretBackCircleSharp } from "react-icons/io5";
import { IoBarChartSharp } from "react-icons/io5";
import { useParams } from 'react-router';
import Loader from '../../components/Loader/Loader';
const Chartrr = ({ dispatch, props }) => {
  const history = useHistory();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { appSettingData, gameList, chartdata, loading } = users;
  let parmas = useParams()

  useEffect(() => {

    dispatch(bankActions.calander({ gameId: parmas?.gameId }));
  }, [parmas])



  return (
    <>
      <Loader loading={loading} />
      <div className='bg-[#EEEEEE] h-screen  w-full '>
        <div className='flex justify-between place-items-center h-16 items-center w-full border bgcolor rounded-b-3xl'>
          <div className=" flex justify-start place-items-end px-4 gap-3">
            <IoArrowBackOutline
              className="text-white mb-1 text-lg"
              onClick={() => window.history.back()}
            />
            <div className="  font-semibold text-white cursor-pointer capitalize">
              {`${parmas?.gameName} Chart`}

            </div>
          </div>
        </div>
        <div className='flex space-x-1 p-1 h-[90vh]  overflow-y-scroll'>
          <div className='w-full py-4'>
            <div className='overflow-hidden overflow-x-auto '>
              <table class="min-w-full text-xs border border-gray-400 ">
                <thead>
                  <tr class="bg-yellow-300  border-2 border-gray-400">
                    <th class=" text-center border-2 border-gray-400 text-xs font-medium text-[#17273B] uppercase tracking-wider">Date</th>
                    <th class=" text-center border-2 border-gray-400 text-xs font-medium text-[#17273B] uppercase tracking-wider">MON</th>
                    <th class=" text-center border-2 border-gray-400 text-xs font-medium text-[#17273B] uppercase tracking-wider">TUE</th>
                    <th class=" text-center border-2 border-gray-400 text-xs font-medium text-[#17273B] uppercase tracking-wider">WED</th>
                    <th class=" text-center border-2 border-gray-400 text-xs font-medium text-[#17273B] uppercase tracking-wider">THU</th>
                    <th class=" text-center border-2 border-gray-400 text-xs font-medium text-[#17273B] uppercase tracking-wider">FRI</th>
                    <th class=" text-center border-2 border-gray-400 text-xs font-medium text-[#17273B] uppercase tracking-wider">SAT</th>
                    <th class=" text-center border-2 border-gray-400 text-xs font-medium text-[#17273B] uppercase tracking-wider">SUN</th>
                  </tr>
                </thead>
                <tbody class="bg-white border border-gray-400">
                  {chartdata && chartdata.length > 0 && chartdata ? chartdata?.map((week, index) => {
                    const startDate = new Date(week.rWeek);
                    const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                    const formattedStartDate = startDate.toISOString().split('T')[0];
                    const formattedEndDate = endDate.toISOString().split('T')[0];

                    return (
                      <tr key={index} class="bg-white border-2 border-gray-400 text-xs">
                        <td class="px-1 whitespace-nowrap border-2 border-gray-400">
                          <div className='flex flex-col text-[9px] font-semibold justify-center items-center'>
                            <div>
                              {formattedStartDate}
                            </div>
                            to
                            <div>
                              {formattedEndDate}
                            </div>
                          </div>
                        </td>
                        <td class="px-1 text-[9px] font-medium whitespace-nowrap border-2 border-gray-400">
                          {<div className='flex gap-1 justify-center items-center'>
                            <div>
                              <div>{week?.MON?.openNumber && week?.MON?.openNumber.toString()[0] || "*"}</div>
                              <div>{week?.MON?.openNumber && week?.MON?.openNumber.toString()[1] || "*"}</div>
                              <div>{week?.MON?.openNumber && week?.MON?.openNumber.toString()[2] || "*"}</div>
                            </div>
                            <div className=' text-red-500'>{week?.MON?.jodiNumber && week?.MON?.jodiNumber.toString()[0] || "*"} {week?.MON?.jodiNumber && week?.MON?.jodiNumber[1] || "*"}</div>
                            <div className=' '>
                              <div>{week?.MON?.closeNumber && week?.MON?.closeNumber.toString()[0] || "*"}</div>
                              <div>{week?.MON?.closeNumber && week?.MON?.closeNumber.toString()[1] || "*"}</div>
                              <div>{week?.MON?.closeNumber && week?.MON?.closeNumber.toString()[2] || "*"}</div>
                            </div>
                          </div>}
                        </td>

                        <td class="px-1 text-[9px] font-medium whitespace-nowrap border-2 border-gray-400">
                          {<div className='flex gap-1 justify-center items-center'>
                            <div>
                              <div>{week?.TUE?.openNumber && week?.TUE?.openNumber.toString()[0] || "*"}</div>
                              <div>{week?.TUE?.openNumber && week?.TUE?.openNumber.toString()[1] || "*"}</div>
                              <div>{week?.TUE?.openNumber && week?.TUE?.openNumber.toString()[2] || "*"}</div>
                            </div>
                            <div className=' text-red-500'>{week?.TUE?.jodiNumber && week?.TUE?.jodiNumber.toString()[0] || "*"} {week?.TUE?.jodiNumber && week?.TUE?.jodiNumber[1] || "*"}</div>
                            <div className=' '>
                              <div>{week?.TUE?.closeNumber && week?.TUE?.closeNumber.toString()[0] || "*"}</div>
                              <div>{week?.TUE?.closeNumber && week?.TUE?.closeNumber.toString()[1] || "*"}</div>
                              <div>{week?.TUE?.closeNumber && week?.TUE?.closeNumber.toString()[2] || "*"}</div>
                            </div>
                          </div>}
                        </td>

                        <td class="px-1 text-[9px] font-medium whitespace-nowrap border-2 border-gray-400">
                          {<div className='flex gap-1 justify-center items-center'>
                            <div>
                              <div>{week?.WED?.openNumber && week?.WED?.openNumber.toString()[0] || "*"}</div>
                              <div>{week?.WED?.openNumber && week?.WED?.openNumber.toString()[1] || "*"}</div>
                              <div>{week?.WED?.openNumber && week?.WED?.openNumber.toString()[2] || "*"}</div>
                            </div>
                            <div className=' text-red-500'>{week?.WED?.jodiNumber && week?.WED?.jodiNumber.toString()[0] || "*"} {week?.WED?.jodiNumber && week?.WED?.jodiNumber[1] || "*"}</div>
                            <div className=' '>
                              <div>{week?.WED?.closeNumber && week?.WED?.closeNumber.toString()[0] || "*"}</div>
                              <div>{week?.WED?.closeNumber && week?.WED?.closeNumber.toString()[1] || "*"}</div>
                              <div>{week?.WED?.closeNumber && week?.WED?.closeNumber.toString()[2] || "*"}</div>
                            </div>
                          </div>}
                        </td>

                        <td class="px-1 text-[9px] font-medium whitespace-nowrap border-2 border-gray-400">
                          {<div className='flex gap-1 justify-center items-center'>
                            <div>
                              <div>{week?.THU?.openNumber && week?.THU?.openNumber.toString()[0] || "*"}</div>
                              <div>{week?.THU?.openNumber && week?.THU?.openNumber.toString()[1] || "*"}</div>
                              <div>{week?.THU?.openNumber && week?.THU?.openNumber.toString()[2] || "*"}</div>
                            </div>
                            <div className=' text-red-500'>{week?.THU?.jodiNumber && week?.THU?.jodiNumber.toString()[0] || "*"} {week?.THU?.jodiNumber && week?.THU?.jodiNumber[1] || "*"}</div>
                            <div className=' '>
                              <div>{week?.THU?.closeNumber && week?.THU?.closeNumber.toString()[0] || "*"}</div>
                              <div>{week?.THU?.closeNumber && week?.THU?.closeNumber.toString()[1] || "*"}</div>
                              <div>{week?.THU?.closeNumber && week?.THU?.closeNumber.toString()[2] || "*"}</div>
                            </div>
                          </div>}
                        </td>

                        <td class="px-1 text-[9px] font-medium whitespace-nowrap border-2 border-gray-400">
                          {<div className='flex gap-1 justify-center items-center'>
                            <div>
                              <div>{week?.FRI?.openNumber && week?.FRI?.openNumber.toString()[0] || "*"}</div>
                              <div>{week?.FRI?.openNumber && week?.FRI?.openNumber.toString()[1] || "*"}</div>
                              <div>{week?.FRI?.openNumber && week?.FRI?.openNumber.toString()[2] || "*"}</div>
                            </div>
                            <div className=' text-red-500'>{week?.FRI?.jodiNumber && week?.FRI?.jodiNumber.toString()[0] || "*"} {week?.FRI?.jodiNumber && week?.FRI?.jodiNumber[1] || "*"}</div>
                            <div className=' '>
                              <div>{week?.FRI?.closeNumber && week?.FRI?.closeNumber.toString()[0] || "*"}</div>
                              <div>{week?.FRI?.closeNumber && week?.FRI?.closeNumber.toString()[1] || "*"}</div>
                              <div>{week?.FRI?.closeNumber && week?.FRI?.closeNumber.toString()[2] || "*"}</div>
                            </div>
                          </div>}
                        </td>

                        <td class="px-1 text-[9px] font-medium whitespace-nowrap border-2 border-gray-400">
                          {<div className='flex gap-1 justify-center items-center'>
                            <div>
                              <div>{week?.SAT?.openNumber && week?.SAT?.openNumber.toString()[0] || "*"}</div>
                              <div>{week?.SAT?.openNumber && week?.SAT?.openNumber.toString()[1] || "*"}</div>
                              <div>{week?.SAT?.openNumber && week?.SAT?.openNumber.toString()[2] || "*"}</div>
                            </div>
                            <div className=' text-red-500'>{week?.SAT?.jodiNumber && week?.SAT?.jodiNumber.toString()[0] || "*"} {week?.SAT?.jodiNumber && week?.SAT?.jodiNumber[1] || "*"}</div>
                            <div className=' '>
                              <div>{week?.SAT?.closeNumber && week?.SAT?.closeNumber.toString()[0] || "*"}</div>
                              <div>{week?.SAT?.closeNumber && week?.SAT?.closeNumber.toString()[1] || "*"}</div>
                              <div>{week?.SAT?.closeNumber && week?.SAT?.closeNumber.toString()[2] || "*"}</div>
                            </div>
                          </div>}
                        </td>

                        <td class="px-1 text-[9px] font-medium whitespace-nowrap border-2 border-gray-400">
                          {<div className='flex gap-1 justify-center items-center'>
                            <div>
                              <div>{week?.SUN?.openNumber && week?.SUN?.openNumber.toString()[0] || "*"}</div>
                              <div>{week?.SUN?.openNumber && week?.SUN?.openNumber.toString()[1] || "*"}</div>
                              <div>{week?.SUN?.openNumber && week?.SUN?.openNumber.toString()[2] || "*"}</div>
                            </div>
                            <div className='text-red-500'>{week?.SUN?.jodiNumber && week?.SUN?.jodiNumber.toString()[0] || "*"} {week?.SUN?.jodiNumber && week?.SUN?.jodiNumber[1] || "*"}</div>
                            <div className=' '>
                              <div>{week?.SUN?.closeNumber && week?.SUN?.closeNumber.toString()[0] || "*"}</div>
                              <div>{week?.SUN?.closeNumber && week?.SUN?.closeNumber.toString()[1] || "*"}</div>
                              <div>{week?.SUN?.closeNumber && week?.SUN?.closeNumber.toString()[2] || "*"}</div>
                            </div>
                          </div>}
                        </td>
                      </tr>
                    )

                  }) : <td colSpan={8} className="px-6 py-2 text-sm text-[#313131] whitespace-nowrap text-center">No Data Found</td>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(Chartrr));
import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { starlineAction } from "../../_actions";
import moment from "moment";
import Loader from "../../components/Loader/Loader";

function IndianStarlineResultHistory() {
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const [datedata, setdatedata] = useState("");
  const currentDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    let data = {
      filterDate: datedata ? datedata : "",
    };
    dispatch(starlineAction.calanderForStarLine(data));
  }, [datedata]);

  const handleOnChange = (e) => {
    setdatedata(e.target.value);

  }

  const goBack = () => {
    window.history.back();
  };

  let { starline } = selector ? selector : {};
  let { getStarlineChartList, loading } = starline ? starline : {};
  let { gameResults, timingarray } = getStarlineChartList ? getStarlineChartList : {};
  console.log("getStarlineChartList", getStarlineChartList)
  return (
    <>
      <Loader loading={loading} />
      <div className="bg-white text-black min-h-screen">
        <div className="h-16 w-auto border bgcolor rounded-b-3xl flex justify-start items-center px-4 gap-3">
          <IoArrowBackOutline className="text-white cursor-pointer h-6 w-6" onClick={goBack} />
          <div className="font-semibold text-white cursor-pointer uppercase">Starline Result History</div>
        </div>

        <div className="flex justify-start space-x-3 items-center text-sm p-4">
          <p className="">Select Date:</p>
          <input type="date" max={currentDate} onChange={(e) => handleOnChange(e)} className="px-3 py-0.5 uppercase rounded-full appearance-none border border-gray-400 text-sm" />
        </div>

        <div>
          <div className="h-screen overflow-y-auto pb-28 px-4">
            {gameResults &&
              gameResults.length > 0 &&
              gameResults?.map((item, index) => (
                <div
                  key={index}
                  className="mb-4 p-4 border rounded-md shadow-md text-xs"
                >
                  <div className="mb-2">
                    <strong>Date: </strong>
                    {item.date}
                  </div>
                  <div className="grid grid-cols-1">
                    {item.results.map((result, idx) => (
                      <div
                        key={idx}
                        className="p-2 border flex justify-between rounded-md shadow-sm my-1"
                      >
                        <div>
                          <div>
                            {moment(result.gameTime, "HH:mm").format("hh:mm A")}
                          </div>
                        </div>
                        <div>{result?.jodiNumber}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>

      </div>
    </>
  );
}

export default IndianStarlineResultHistory;

import React, { useEffect } from 'react'
import { IoIosRefresh } from 'react-icons/io'
import { IoArrowBackOutline } from 'react-icons/io5'
import { RiWhatsappFill } from "react-icons/ri";
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { userActions } from '../../_actions';

const NoticeBoard = () => {

    const dispatch = useDispatch()
    let selector = useSelector(state => state)
    let { users } = selector ? selector : {}
    let { appSettingData } = users;


    // console.log("appSettingDataappSettingData", appSettingData);


    useEffect(() => {
        dispatch(userActions.getAllAppSetting());
    }, [])
    const handleGoBack = () => {
        window.history.back()
    }
    const handleRefresh = () => {
        window.location.reload();
    };
    return (
        <div className='h-screen bg-white'>
            <div className='h-16 w-full border bgcolor rounded-b-3xl flex items-center justify-between place-items-end '>
                <div className='flex justify-start px-4 gap-3'>
                    <IoArrowBackOutline className='text-white mb-1 text-lg' onClick={handleGoBack} />
                    <div className='font-semibold text-white cursor-pointer'>Notice / Board</div>

                </div>
                <div>
                    <IoIosRefresh
                        className="text-2xl mb-1 cursor-pointer text-white mx-4"
                        onClick={handleRefresh}
                    />
                </div>
            </div>
            <section>
                <div className="mt-8 mx-4 text-center">

                    <div className=' bg-white p-4 rounded-md mb-10 '>
                        {appSettingData && appSettingData.length > 0 && appSettingData.map((e) => {

                            if (e && e.key === "NOTICE") {
                                return (
                                    <div dangerouslySetInnerHTML={{ __html: e.value }} />
                                )
                            }
                        })}
                    </div>

                    <div className="my-8 border-t-2 border-b-2 border-black  flex justify-center place-items-center">
                        <RiWhatsappFill className='text-green-500 text-xl' />
                        <div className=' bg-white p-5 rounded-md '>
                            {appSettingData && appSettingData.length > 0 && appSettingData.map((e) => {

                                if (e && e.key === "WhatsApp") {
                                    return (
                                        <>
                                            <p>{e?.value} </p>
                                            {/* <input value={e?.value} disable className='bg-gray-50 border cursor-not-allow border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-full p-2.5 ' /> */}
                                        </>
                                    )
                                }
                            })}

                        </div>


                    </div>



                </div>

            </section >
        </div >
    )
}

function mapStateToProps(state) {
    const { users } = state;
    return {
        users
    };
}

export default withRouter(connect(mapStateToProps)(NoticeBoard)) 
import { jackpotChartConstant } from "../_constants";
import { userService } from '../_services';
import { alertActions } from './';



export const jackportchartAction = {
    calanderForJackpot
}

function calanderForJackpot(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.calanderForJackpot(data)

            .then(
                user => {
                    dispatch(success(user));

                },
                error => {

                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: jackpotChartConstant.GET_JACKPOT_CHART_LIST_REQUEST, user } }
    function success(user) { return { type: jackpotChartConstant.GET_JACKPOT_CHART_LIST_SUCCESS, user } }
    function failure(error) { return { type: jackpotChartConstant.GET_JACKPOT_CHART_LIST_FAILURE, error } }
}
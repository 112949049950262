export const starlineConstant={
    GET_STARLINE_BID_LIST_REQUEST:"GET_STARLINE_BID_LIST_REQUEST",
    GET_STARLINE_BID_LIST_SUCCESS:"GET_STARLINE_BID_LIST_SUCCESS",
    GET_STARLINE_BID_LIST_FAILURE:"GET_STARLINE_BID_LIST_FAILURE",


    GET_STARLINE_CHART_LIST_REQUEST:"GET_STARLINE_CHART_LIST_REQUEST",
    GET_STARLINE_CHART_LIST_SUCCESS:"GET_STARLINE_CHART_LIST_SUCCESS",
    GET_STARLINE_CHART_LIST_FAILURE:"GET_STARLINE_CHART_LIST_FAILURE",
 
}
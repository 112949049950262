import { updateBankDetail } from "../_constants";
import { userService } from '../_services';
import { alertActions, userActions } from './';


export const updateBankDetailsAction = {
    saveClientBankDetail
}

function saveClientBankDetail(data, props) {
    console.log(data);
    
    return dispatch => {
        dispatch(request({ data }));
        userService.saveClientBankDetail(data)
        
            .then(
                user => {
                    dispatch(success(user));
                   
                    dispatch(userActions.getClientBankDetail())
                
                    
                },
                error => {

                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: updateBankDetail.SAVE_CLINT_DETAILS_REQUEST, user } }
    function success(user) { return { type: updateBankDetail.SAVE_CLINT_DETAILS_SUCCESS, user } }
    function failure(error) { return { type: updateBankDetail.SAVE_CLINT_DETAILS_FAILURE, error } }
}
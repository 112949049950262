import { updateBankDetail } from "../_constants";


export function updateBankDetails(state = {}, action) {
    switch (action.type) {

        case  updateBankDetail.SAVE_CLINT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case  updateBankDetail.SAVE_CLINT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
              
            
            };
        case  updateBankDetail.SAVE_CLINT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        default:
            return state
    }
}
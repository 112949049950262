import React from "react";
import { connect } from "react-redux";
import { DomainName } from "../../_config/index";
import { alertActions, userActions } from "../../_actions";
import moment from "moment-timezone";
import RulesNotification from "../../components/RulesNotification/RulesNotification";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BetCloseAlert from "../../components/CloseBetAlert/CloseBetAlert";
import { BsStopwatch } from "react-icons/bs";
import { IoCloseCircle } from "react-icons/io5";
import { FaPlayCircle, FaRegCalendar } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import { IoArrowBack } from "react-icons/io5";
import { FaPause } from "react-icons/fa";
import SorryMessage from "../../components/SorryModel/SorryMessage";
import HeaderWallet from "../Wallet/HeaderWallet";
import { RiCalendarScheduleLine } from "react-icons/ri";
import { TbClockStop } from "react-icons/tb";

class JodiListIndian extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      notification: false,
      editStake: false,
      timeDifference: null,
      isOpenMessage: false,
      isPastClosingTime: false,
    };
  }

  componentDidMount() {
    // const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    // if (!hasSeenPopup) {
    //   this.setState({ notification: true });
    //   localStorage.setItem('hasSeenPopup', true);
    // }
    let data = {
      keyWord: "",
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 100,
    };

    this.props.dispatch(userActions.getAllSlider());
    this.props.dispatch(userActions.getAllAppSetting());
    this.props.dispatch(userActions.getAllJodiGame(data));
    this.props.dispatch(userActions.getProfile());
    // this.intervalId = setInterval(() => {
    // this.props.dispatch(userActions.getGamesList(data));

    // }, 2000);
  }

  // componentWillUnmount() {

  //   clearInterval(this.intervalId);
  // }

  navigateGameDetailsPage = (data, booleanValue) => {
    // console.log("booleanValue",booleanValue)
    if (!booleanValue) {
      // alertActions.error("Closed for now")
      this.setState({ isOpenMessage: true });
      return;
    }
    const closingTimeInMillis = data.closingTime;
    const currentTimeInMillis = new Date().getTime();

    if (currentTimeInMillis < closingTimeInMillis) {
      this.props.history.push(`/app/matkaDetails2/${data.name}/${data._id}`);
    } else {
      // this.setState({ isPastClosingTime: true });
      this.props.history.push(`/app/matkaDetails2/${data.name}/${data._id}`);
    }
  };

  handleCloseAlert = () => {
    this.setState({ isPastClosingTime: false });
  };

  handleCloseNotification = () => {
    this.setState({ notification: false });
    localStorage.setItem("hasSeenPopup", true);
  };
  navigate = () => {
    this.props.history.push(`/app/indian-jackpot-result`);
  }

  closemodel = () => {
    this.setState({ isOpenMessage: false });
  };
  render() {
    const { activeTab, notification, isPastClosingTime } = this.state;
    let { users } = this.props;
    let {
      totalGame,
      loading,
      gameList,
      brandData,
      currentTimeApi,
      appSettingData,
      slideData,
      indianJackPotGames,
    } = users;

    // const currentTimeApis = moment.utc(currentTimeApi * 1000).format("hh:mm A");
    const currentTimeApis = moment
      .utc(currentTimeApi * 1000)
      .tz("Asia/Kolkata")
      .format("hh:mm A");
    let recentList = [
      {
        name: "Sridevi",
        closingTime: 211331313131,
        openTime: 1131313131312,
      },
    ];

    var settings = {
      button: false,
      dots: true,
      slickPrev: false,
      slickNext: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <>
        {/* <div className='w-full ' style={{ backgroundImage: "url(/images/bg-all.jpg)" }}> */}
        <div className="w-full bg-gray-100">
          <Loader loading={loading} />
          <div className="flex space-x-1">
            <div className="w-full relative space-x-1">
              <div className="bgcolor flex items-center justify-between text-white text-3xl px-3 h-16 rounded-b-3xl relative">
                <IoArrowBack className="cursor-pointer left-3" onClick={() => this.props.history.goBack()} />
                <div className="uppercase font-bold text-base">
                  <span className="">JackPot</span>
                </div>
                <div className="flex justify-start gap-1 place-items-center p-1.5 rounded-md text-sm">
                  <HeaderWallet />
                </div>
              </div>

              <div className="w-full space-y-1 h-screen overflow-y-auto pb-24 px-4">
                <div className="overflow-hidden w-full">

                  <div className="flex justify-between items-center py-4">
                    <div className="">
                      <span className="bg-white shadow-md rounded-3xl px-10 py-1 font-semibold">
                        Jodi <span className="textcolor">1-100</span>
                      </span>
                    </div>
                    <div className="bg-white cursor-pointer shadow-md rounded-3xl px-10 py-1 flex items-center justify-between" onClick={this.navigate}>
                      <RiCalendarScheduleLine />
                      <span className="font-semibold pl-4">Chart</span>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    {indianJackPotGames && indianJackPotGames.length > 0
                      ? indianJackPotGames.map((element, index) => {
                        const closeCloseBidTime = moment
                          .duration(element?.betOpenTime, "seconds")
                          .asMinutes();

                        const openingTime = moment()
                          .hours(parseInt(element && element.openTime / 3600))
                          .minutes(
                            parseInt(
                              (element && element.openTime % 3600) / 60,
                            ),
                          )
                          .seconds(parseInt(element && element.openTime % 60))
                          .milliseconds(0);

                        const closingTime = moment()
                          .hours(
                            parseInt(element && element.closingTime / 3600),
                          )
                          .minutes(
                            parseInt(
                              (element && element.closingTime % 3600) / 60,
                            ),
                          )
                          .seconds(
                            parseInt(element && element.closingTime % 60),
                          )
                          .milliseconds(0);

                        const OpenBetTime = openingTime.format("hh:mm A");

                        const CloseBetTime = closingTime.format("hh:mm A");

                        const getCurrentTime = () => {
                          const now = moment();
                          return now.format("hh:mm:A");
                        };
                        const currentTimeApis = getCurrentTime();

                        const thirtyMinutesBeforeClosing = closingTime
                          .clone()
                          .subtract(closeCloseBidTime, "minutes");

                        const isBefore = moment(
                          currentTimeApis,
                          "hh:mm A",
                        ).isBefore(
                          moment(thirtyMinutesBeforeClosing, "hh:mm A"),
                        );

                        return (
                          <div
                            onClick={() =>
                              this.navigateGameDetailsPage(
                                element,
                                isBefore &&
                                element?.market?.jodiNumber == null,
                              )
                            }
                            className=""
                          >
                            <div className="flex bg-white items-center p-2 w-full">
                              <div className="w-full">
                                <div className="flex justify-between items-center w-full">
                                  <p>{(OpenBetTime && OpenBetTime) || ""}</p>
                                  {isBefore &&
                                    element?.market?.jodiNumber == null ? (
                                    <div className="textcolor">
                                      <BsStopwatch className="w-6 h-6" />
                                    </div>
                                  ) : (
                                    <div className="text-gray-600">
                                      <TbClockStop className="w-6 h-6" />
                                    </div>
                                  )}
                                </div>
                                <div className="flex justify-center items-center">
                                  <div className="flex justify-center items-center bg-black rounded-full p-1 w-8 h-8 text-center font-bold text-yellow-400">
                                    {(element &&
                                      element?.market &&
                                      element?.market?.jodiNumber &&
                                      element?.market?.jodiNumber.split(
                                        "",
                                      )[0]) ||
                                      "*"}
                                    {(element &&
                                      element?.market &&
                                      element?.market?.jodiNumber &&
                                      element?.market?.jodiNumber.split(
                                        "",
                                      )[1]) ||
                                      "*"}
                                  </div>
                                </div>
                                {isBefore &&
                                  element?.market?.jodiNumber == null ? (
                                  <div className="text-[10px] text-center font-semibold text-green-700">Running Now</div>
                                ) : (
                                  <div className="text-[10px] text-center font-semibold pt-2 text-red-700">Close for today</div>
                                )}
                              </div>
                            </div>

                            {isBefore &&
                              element?.market?.jodiNumber == null ? (
                              <div className=" bgcolor px-2 text-white gap-3 flex justify-center items-center py-1 ">
                                <FaPlayCircle className="w-5 h-5" /> Play Game
                              </div>
                            ) : (
                              <div className=" bg-gray-500 px-2 text-white gap-3 flex justify-center items-center py-1">
                                <IoCloseCircle className="w-6 h-6" /> Close Game
                              </div>
                            )}
                          </div>
                        );
                      })
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <SorryMessage
              isOpenMessage={this.state.isOpenMessage}
              closemodel={this.closemodel}
            />
          </div>
          {this.state.isPastClosingTime ? (
            <BetCloseAlert handleCloseAlert={this.handleCloseAlert} />
          ) : null}

          {notification && (
            <RulesNotification
              handleCloseNotification={this.handleCloseNotification}
              DomainName={DomainName}
            />
          )}
        </div>
        {/* <Footer props={this.props} /> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(JodiListIndian);

import { paymentConstant } from "../_constants";

export function Payment(state = {}, action) {
    switch (action.type) {

        case paymentConstant.GET_PAYMENT_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case paymentConstant.GET_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getPaymentListList: action?.user?.getClientPaymentList?.data,

            };
        case paymentConstant.GET_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };



        default:
            return state
    }
}
import { gameResult } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./alert.actions";

export const gameResultAction = {
    gameResultList,
    gameResults
}


function gameResultList(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.calanderForKolkataFatafat(data)
            .then(
                user => {
                    dispatch(success(user));

                },
                error => {

                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: gameResult.GAME_RESULT_LIST_REQUEST, user } }
    function success(user) { return { type: gameResult.GAME_RESULT_LIST_SUCCESS, user } }
    function failure(error) { return { type: gameResult.GAME_RESULT_LIST_FAILURE, error } }
}





function gameResults(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.gameResults(data)
            .then(
                user => {
                    dispatch(success(user));

                },
                error => {

                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: gameResult.GAME_RESULT_REQUEST, user } }
    function success(user) { return { type: gameResult.GAME_RESULT_SUCCESS, user } }
    function failure(error) { return { type: gameResult.GAME_RESULT_FAILURE, error } }
}
import React from 'react';
import img from "./cross.png"
import { IoCloseOutline } from "react-icons/io5";
import { RiCloseLargeLine } from "react-icons/ri";

const SorryMessage = ({ isOpenMessage, closemodel }) => {
  const handleClose = (e) => {
    // Close the modal if clicking outside the modal content
    if (e.target === e.currentTarget) {
      closemodel();
    }
  };

  return (
    <>
      <div className={isOpenMessage ? "fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center" : "hidden"} onClick={handleClose}>
        <div className="bg-white p-3 text-center rounded-lg border-2 border-orange-500 shadow-lg sm:max-w-sm w-full">
          <div className='flex justify-end'>
            <IoCloseOutline className='text-lg cursor-pointer bg-gray-200 text-gray-600 hover:bg-red-600 hover:text-white rounded-full h-6 w-6' onClick={closemodel} />
          </div>
          <div className='flex justify-center items-center'>
            <RiCloseLargeLine className='w-14 h-14 text-white bg-gradient-to-r from-red-500 to-orange-500 border rounded-full p-2' />
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold text-red-600 my-3">Sorry!</h1>
            <p className="text-base text-gray-700">Betting is closed for today.</p>
            <p className="text-base text-gray-700">Please check back tomorrow <br />to place your bets.</p>
            <button className="my-4 px-4 py-2 bgcolor sm:w-56 w-full text-white font-semibold rounded hover:bg-red-700" onClick={closemodel}>OK</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SorryMessage;

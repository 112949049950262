import React from 'react';
import { connect } from 'react-redux';
import { alertActions, userActions } from '../../_actions';
import moment from 'moment'
import { IoCaretBackCircle } from "react-icons/io5";
import Loader from '../../components/Loader/Loader';
import Selector from './Selector';
import ConfirmationModel from '../../components/ConfirmationModel';
// import moment from 'moment';
import { IoArrowBack } from "react-icons/io5";

class MatkaSingle extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameOne: '',
      gameTwo: '',
      gameThree: '',
      isModelOpen: false,
      gameFour: '',
      gameItem: {},
      activeTab: 1,
      editStake: false,
      arrayofBets: [],
      selectedOption: null,
      fieldStake: {},
      errorStake: {},
      fieldMarket: {},
      errorMarket: {},
      timeLeft: "",
      currentTime: moment().format('hh:mm:ss A'),
      data: {
        "limit": 10,
        "pageno": 1
      }
    }

  }
  componentDidMount() {
    this.timerInterval = setInterval(this.updateTimeLeft, 1000);
    let data = {
      "marketId": this.props.match.params.marketId,
    }
    this.props.dispatch(userActions.getMarketByMarketId(data));
    this.props.dispatch(userActions.getGamesByGamesIdForUser({ "gameId": this.props.match.params.gameId }))
    this.props.dispatch(userActions.getProfile())
    let objdata = {
      gameId: this.props.match.params.gameId,
      marketId: data.marketId,
    }
    this.props.dispatch(userActions.todayBets(objdata))
  }
  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  updateTimeLeft = () => {
    const { users } = this.props;
    const { gameData } = users;
    const { list } = gameData ? gameData : {};
    const { openTime, closingTime } = list ? list : {};

    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const currentTime = moment();
    const timeLeft = closingTimes.diff(currentTime);

    if (timeLeft <= 0) {
      clearInterval(this.timerInterval);
      this.setState({ timeLeft: '00:00:00' });
    } else {
      const duration = moment.duration(timeLeft);
      const hours = String(duration.hours()).padStart(2, '0');
      const minutes = String(duration.minutes()).padStart(2, '0');
      const seconds = String(duration.seconds()).padStart(2, '0');

      this.setState({
        timeLeft: `${hours}:${minutes}:${seconds}`,
        currentTime: currentTime.format('hh:mm:ss A'),
      });
    }
  };

  handleStakeChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = parseFloat(value) >= 0 ? value : 0;
    errorStake[name] = "data not found";
    console.log("value  ", value);

    let sum = 0;

    for (const key in fieldStake) {
      if (fieldStake.hasOwnProperty(key)) {
        sum += parseInt(fieldStake[key]);
      }
    }

    this.setState({ fieldStake, errorStake, totalPoint: sum });
  };

  handleChangeSelect = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldMarket, errorStake } = this.state;
    fieldMarket[name] = value;
    errorStake[name] = "data not found";
    this.setState({ fieldMarket, errorStake });
    this.setState({
      errorStake: {
        ...this.state.errorStake,
        market: "",
        betNumber: '',
      }
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    let { users } = this.props;
    let { gameData
    } = users;
    let { list } = gameData ? gameData : {};
    let { max, min } = list ? list : {};
    let { name, value } = e.target;
    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = Number(value)

    this.setState({ fieldStake, errorStake });

  }


  placeBet = () => {
    this.props.dispatch(userActions.getGamesByGamesIdForUser({ "gameId": this.props.match.params.gameId }))
    let { users } = this.props;
    let { marketDetails, marketLoading, getProfile,
      gameData
    } = users;
    let { list, jodiNumber } = gameData ? gameData : {};
    let { openTime, closingTime,betOpenTime,onOf } = list ? list : {};
    let { wallet
    } = getProfile ? getProfile : {}

    let { min, max } = marketDetails ? marketDetails : {};
    if(!onOf){
      this.props.dispatch(alertActions.error("Attention", "Game is closed"));
      return;
    }
    if (this.state.arrayofBets && this.state.arrayofBets.length == 0) {
      this.props.dispatch(alertActions.error("Attention", `No bets placed`));
      return;
    }
    if (this.state.arrayofBets.length !== 0) {
      for (let i = 0; i < this.state.arrayofBets.length; i++) {
        if (this.state.arrayofBets[i]?.amount < min) {
          this.props.dispatch(alertActions.error("Attention", `Bet amount should be more than ${min}`));
          return;
        } else if (this.state.arrayofBets[i]?.amount > max) {
          this.props.dispatch(alertActions.error("Attention", `Bet amount should be less than ${max}`));
          return;
        }
      }
    }
 


    const closeCloseBidTime = moment.duration(betOpenTime, 'seconds').asMinutes()


    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const OpenBetTime = openingTime.format("hh:mm A");

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const CloseBetTime = closingTimes.format("hh:mm A");


    const getCurrentTime = () => {
      const now = moment();
      return now.format('hh:mm:A');
    };
    const thirtyMinutesBeforeClosing = openingTime.clone().subtract(closeCloseBidTime, 'minutes');

    const currentTimeApis = getCurrentTime()
    const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(moment(thirtyMinutesBeforeClosing, "hh:mm A"));


    if (!isBefore) {
      this.props.dispatch(alertActions.error("Attention", `Bets are closed, Betting time is ${CloseBetTime}`));
      return;
    }
    if (jodiNumber != null) {
      this.props.dispatch(alertActions.error("Attention", `Result is Declared`));
      return;
    }
    if (this.handleValid()) {
      let reqData = {
        "marketId": this.props.match.params.marketId,
        "gameId": this.props.match.params.gameId,
        "bets": this.state.arrayofBets,
        "isOpen": true,
        "parentId": getProfile?.parentId || null,
        "upline": getProfile?.upline || [],
      }

      this.props.dispatch(userActions.placeBet(reqData, this.props));
    }

  }


  handleValid = () => {
    let valid = true;
    let { fieldStake, fieldMarket } = this.state;
    let errorMessage = {}
    let bets = Object.keys(fieldStake).map(key => ({ "betNumber": key, "amount": parseInt(fieldStake[key]) }));


    if (this.state.arrayofBets && this.state.arrayofBets.length == 0) {
      valid = false;
      errorMessage["bet"] = "Bets not found"

    }
    this.setState({ errorStake: { ...errorMessage } })

    return valid

  }

  setSelectedOption = (e) => {
    this.setState({ selectedOption: e?.value })
  }

  addinArray = () => {
    const { amount } = this.state.fieldStake;
    let betNumber = this.state.selectedOption;
    let { users } = this.props;
    let { gameData
    } = users;
    let { list } = gameData ? gameData : {};
    let { max, min } = list ? list : {};
    // Checking if amount is within valid range
    //  console.log("----------------amountamount",amount, max, min ,this.state.selectedOption)

    if (Number(amount) > max) {
      this.props.dispatch(alertActions.warn(`Attention`, `Max bid amount is ${max}`));
      return

    }
    if (Number(amount) < min) {
      this.props.dispatch(alertActions.warn("Attention", `Min bid amount is ${min}`));
      return

    }
    if (betNumber !== undefined && amount !== undefined && betNumber !== null && this.state.selectedOption !== null) {
      const betIndex = this.state.arrayofBets.findIndex(bet => bet.betNumber === betNumber);

      if (betIndex !== -1) {
        this.setState(prevState => {
          const updatedBets = [...prevState.arrayofBets];
          updatedBets[betIndex] = { ...updatedBets[betIndex], amount: amount };
          return { arrayofBets: updatedBets, fieldStake: {} };
        });
      } else {
        this.setState(prevState => ({
          arrayofBets: [
            ...prevState.arrayofBets,
            { betNumber: betNumber, amount: amount }
          ],
          fieldStake: {}
        }));
        // this.setState({ selectedOption: null })
      }
    } else {

    }
  }

  removeFromArray = (betnumberToRemove) => {
    // console.log("Updated arrayofBetsasdsad:", this.state.arrayofBets);
    this.setState(prevState => ({
      arrayofBets: prevState.arrayofBets.filter(bet => bet.betNumber !== betnumberToRemove?.betNumber)
    }), () => {
      console.log("Updated arrayofBets:", this.state.arrayofBets);
    });
  }

  letOpenModel = () => {
    this.setState({ isModelOpen: true })
  }
  letOpenModelClose = () => {
    this.setState({ isModelOpen: false })
  }
  handleNewChange = async (e) => {
    this.props.dispatch(userActions.getGamesByGamesIdForUser({ "gameId": this.props.match.params.gameId }))
    let { users } = this.props;
    let { marketDetails, marketLoading, getProfile,
      gameData
    } = users;
    let { list, jodiNumber } = gameData ? gameData : {};
    let { openTime, closingTime,betOpenTime } = list ? list : {};
    let { wallet
    } = getProfile ? getProfile : {}
    const closeCloseBidTime = moment.duration(betOpenTime, 'seconds').asMinutes()
    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const OpenBetTime = openingTime.format("hh:mm A");

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const CloseBetTime = closingTimes.format("hh:mm A");


    const getCurrentTime = () => {
      const now = moment();
      return now.format('hh:mm:A');
    };
    const currentTimeApis = getCurrentTime()
    const thirtyMinutesBeforeClosing = openingTime.clone().subtract(closeCloseBidTime, 'minutes');
    const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(moment(thirtyMinutesBeforeClosing, "hh:mm A"));
    // console.log(currentTimeApis, CloseBetTime, isBefore)

    if (!isBefore) {
      this.props.dispatch(alertActions.error("Attention", `Bets are closed, Betting time is ${CloseBetTime}`));
      return;
    }
    if (jodiNumber != null) {
      this.props.dispatch(alertActions.error("Attention", `Result is Declared`));
      return;
    }

    const { name, value } = e.target;
    const { arrayofBets } = this.state;

    if (value.length > 6) {
      return;
    }

    const index = arrayofBets.findIndex(bet => bet.betNumber === name);

    if (value.trim() === '') {
      // Remove bet if value is empty
      const updatedBets = arrayofBets.filter(bet => bet.betNumber !== name);
      this.setState({ arrayofBets: updatedBets });
    } else if (index !== -1) {
      // Update existing bet with new amount
      const updatedBets = [...arrayofBets];
      updatedBets[index] = { ...updatedBets[index], amount: value };
      this.setState({ arrayofBets: updatedBets });
    } else {
      // Add new bet if not found
      const newBet = { betNumber: name, amount: value };
      this.setState(prevState => ({
        arrayofBets: [...prevState.arrayofBets, newBet]
      }));
    }
  }


  render() {
    console.log(this.state.currentTime)
    let { users } = this.props;
    let { marketDetails, marketLoading, getProfile,
      gameData,todayBet
    } = users;
    let { list } = gameData ? gameData : {};
    let { openTime, closingTime } = list ? list : {};
    let { wallet,blockedNumbers
    } = getProfile ? getProfile : {}

    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const OpenBetTime = openingTime.format("hh:mm A");

    // const closingTimes = moment()
    //   .hours(parseInt(closingTime && closingTime / 3600))
    //   .minutes(parseInt((closingTime && closingTime % 3600) / 60))
    //   .seconds(parseInt(closingTime && closingTime % 60))
    //   .milliseconds(0);

    // const CloseBetTime = closingTimes.format("hh:mm A");



    const currentDate = moment();
    const formattedDate = currentDate.format('DD-MMM-YYYY');
    // const formattedDate = currentDate.format('DD-MMM-YYYY');
    const totalAmount = this.state.arrayofBets && this.state.arrayofBets.length > 0
      ? this.state.arrayofBets.reduce((total, bet) => total + parseInt(bet.amount), 0)
      : 0;

      let { min, max } = marketDetails ? marketDetails : {}


      let selectedMarketBlock = blockedNumbers && blockedNumbers.length > 0 && blockedNumbers.filter(e => e.marketId == this.props.match.params.marketId)
      let arrayToblock = selectedMarketBlock && selectedMarketBlock.length > 0 && selectedMarketBlock[0] && selectedMarketBlock[0]?.numbers && selectedMarketBlock[0]?.numbers?.map((e) => e?.numbers)
      let flatarrydatatoBlock = arrayToblock && arrayToblock.length > 0 && arrayToblock.flat(Infinity)||[]
    return (
      <div className='w-full h-full relative  bg-gray-200'>
        <Loader loading={marketLoading} />
        <div className=' text-3xl absolute text-[#524541]'>

          <IoArrowBack className=' cursor-pointer' onClick={() => this.props.history.goBack()} />
        </div>
        <div className="w-full p-2 mb-10 overflow-y-auto h-[90vh]  space-y-5">
          <div className='px-3 uppercase mt-10  bgblue py-1  rounded-lg w-full flex flex-col  text-base font-semibold text-left items-start text-white  '>
            <div className='  border-b border-white w-full '>KOLKATA FATAFAT   DAte : {formattedDate}</div>
            <div className='border-b border-white w-full '>{this.props.match.params.gameName} Time : {OpenBetTime} <span className='md:text-2xl px-2'></span></div>
            {/* <div className=' border-b border-white w-full '>Single Pana </div> */}
            <div className=' border-b border-white w-full flex justify-between '><span>Market : Single Pana </span> <span className=' capitalize'> Min : {min} | Max : {max} </span></div>
            <div className='border-b border-white w-full'>Current Time : {this.state.currentTime} </div>
            <div>Time Left : {this.state.timeLeft} </div>

          </div>


          <div className='bgcolor flex flex-col rounded-lg w-full p-5   space-y-5'>


            <div className='flex  justify-center gap-2  items-center'>



              <div className=' flex flex-1 flex-col justify-center  rounded-lg bg-white  items-center'>
                <div className=' border-b border-black px-3 py-1 font-bold'>Total Bids</div>
                <span className='text-xl flex-1 font-bold uppercase   text-[#3E44D5] p-1 rounded-lg '>{this.state.arrayofBets && this.state.arrayofBets.length || "0"}</span>
              </div>


              <div className=' flex flex-1 flex-col justify-center  rounded-lg bg-white  items-center'>
                <div className=' border-b border-black px-3 py-1 font-bold'>Total Amount</div>
                <span className='text-xl flex-1 font-bold uppercase   text-[#3E44D5] p-1 rounded-lg '>{totalAmount || "0"}</span>
              </div>




            </div>
            <div className='  w-full'>

              {this.state.arrayofBets && this.state.arrayofBets.length > 0 && <button onClick={() => this.letOpenModel()} className=' py-1 w-full  mt-4 bgblue  text-white md:text-2xl text-base rounded-xl '>Submit</button>}

            </div>



            <div className='  text-black pb-14 px-1  gap-4 border-t h-[50vh] overflow-y-scroll overflow-hidden border-white pt-2  flex  flex-col'>
              <div className='pb-2  flex-col   flex  justify-center items-center gap-1'>
                {marketDetails?.numList &&
                  marketDetails.numList.map((elem, index) => {
                    return (
                      <div key={index}>
                        <div className='bgblue  text-white md:text-sm text-xs  mb-1 p-2 rounded justify-center items-center flex'>{index} House</div>
                        <div className='grid xl:grid-cols-11 lg:grid-cols-7  md:grid-cols-8 grid-cols-6 gap-1'>
                          {/* <div className='grid xl:grid-cols-10 lg:grid-cols-7  md:grid-cols-8 grid-cols-5 gap-1'> */}
                          {elem && Array.isArray(elem) && elem.map((e) => {
                             if(!flatarrydatatoBlock?.includes(e?.toString())){
                              return (
                                <div key={e} className="font-bold bgblue rounded-lg p-2 flex flex-col m-0.5 justify-between items-center">
                                  <div className="text-white flex justify-center items-center text-sm">{e}</div>
                                  <input
                                    name={e}
                                    value={this.state.arrayofBets.find(bet => bet.betNumber == e)?.amount || ''}
                                    onChange={this.handleNewChange}
                                    className="md:w-12 w-10 md:text-sm text-xs rounded-md outline-none"
                                    type="number"
                                  />
                                </div>
                              );
                             }
                           
                          })}
                        </div>
                      </div>

                    );
                  })}
              </div>



            </div>

          </div>
          <table className="min-w-full bgblue text-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-200 bgblue text-white text-left text-sm font-medium">Digit</th>
                <th className="py-2 px-4 border-b border-gray-200 bgblue text-white text-left text-sm font-medium">Amt</th>
                <th className="py-2 px-4 border-b border-gray-200 bgblue text-white text-left text-sm font-medium">Type</th>
                <th className="py-2 px-4 border-b border-gray-200 bgblue text-white text-left text-sm font-medium">Time</th>
              </tr>
            </thead>
            <tbody>
              {todayBet && todayBet.length > 0 && todayBet.map((item, index) => (
                <tr key={item.id} className={index % 2 == 0 && "bg-white text-black"}>
                  <td className="py-2 px-4 border-b border-gray-200">{item?.betNumber}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{item.amount}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{item?.isOpen ? "Open" : "Close"}</td>
                  <td className="py-2 px-4 border-b border-gray-200">
                    {new Date(item?.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
            
          {/* {
            this.state.arrayofBets && this.state.arrayofBets.length > 0 && this.state.arrayofBets.map((e, i) => {

              return (
                <div className='bg-white text-base flex justify-between items-center px-3 rounded-lg p-1'>

                  <div>{e?.betNumber}</div>
                  <div>{e?.amount}</div>
                  <div>Open</div>
                  <IoMdTrash onClick={() => this.removeFromArray(e)} className=' cursor-pointer text-red-600' />

                </div>
              )
            })
          } */}
        </div>
        <ConfirmationModel isModelOpen={this.state.isModelOpen} type={"Open"} arrayOfBets={this.state.arrayofBets} walletAmount={wallet
        } letOpenModelClose={this.letOpenModelClose} placeBet={this.placeBet} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(MatkaSingle);

import React from "react";
import { connect } from "react-redux";
import { alertActions, userActions } from "../../_actions";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import { IoArrowBack } from "react-icons/io5";
import { IoMdTrash } from "react-icons/io";
import ConfirmationModel from "../../components/ConfirmationModel";
import SubmitTotalData from "../Submit Total Data/SubmitTotalData";
import HeaderWallet from "../Wallet/HeaderWallet";
import CustomSlider from "../../components/SliderHeader/CustomSlider";
class SPDPTP extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameOne: "",
      gameTwo: "",
      gameThree: "",
      gameFour: "",
      gameItem: {},
      activeTab: 1,
      editStake: false,
      fieldStake: {},
      errorStake: {},
      fieldMarket: {},
      errorMarket: {},
      isModelOpen: false,
      selectMode: "em",
      option: {},
      Point: null,
      Ank: null,
      Pana: {},
      arrayofBets: [],
      timeLeft: "",
      currentTime: moment().format("hh:mm:ss A"),

      data: {
        limit: 10,
        pageno: 1,
      },
    };
  }
  componentDidMount() {
    // this.timerInterval = setInterval(this.updateTimeLeft, 1000);
    let data = {
      marketId: this.props.match.params.marketId,
    };
    this.props.dispatch(
      userActions.getGamesByGamesIdForUser({
        gameId: this.props.match.params.gameId,
      }),
    );
    this.props.dispatch(userActions.getMarketByMarketId(data));
    this.props.dispatch(userActions.getProfile());
    let objdata = {
      gameId: this.props.match.params.gameId,
      marketId: data.marketId,
    };
    this.props.dispatch(userActions.todayBets(objdata));
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.users?.marketDetails !== this.props?.users?.marketDetails) {
      this.processMarketDetails();
    }
  }


  processMarketDetails = async () => {
    let { users } = this.props;
    let { marketDetails } = users;

    if (marketDetails?.numList && marketDetails?.numList.length > 0) {


      let options = marketDetails.numList[0]
      console.log("getAllKeys", options);
      this.setState({ option: options });
    }


  };



  updateTimeLeft = () => {
    const { users } = this.props;
    const { gameData } = users;
    const { list} = gameData
      ? gameData
      : {};
    const { openTime, closingTime } = list ? list : {};

    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const currentTime = moment();
    const timeLeft = closingTimes.diff(currentTime);

    if (timeLeft <= 0) {
      clearInterval(this.timerInterval);
      this.setState({ timeLeft: "00:00:00" });
    } else {
      const duration = moment.duration(timeLeft);
      const hours = String(duration.hours()).padStart(2, "0");
      const minutes = String(duration.minutes()).padStart(2, "0");
      const seconds = String(duration.seconds()).padStart(2, "0");

      this.setState({
        timeLeft: `${hours}:${minutes}:${seconds}`,
        currentTime: currentTime.format("hh:mm:ss A"),
      });
    }
  };

  handleResponseCasino = () => {
    this.props.history.push("/app/satta-events-id");
  };

  handleSidebar = (data) => {
    console.log("datagameItemgameItemgameItem", data);
    this.setState({ gameItem: data });
  };
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  setEditStakeOpen = () => {
    this.setState({ editStake: !this.state.editStake });
  };

  handleStakeChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = parseFloat(value) >= 0 ? value : 0;
    errorStake[name] = "data not found";
    console.log("value  ", value);

    let sum = 0;

    for (const key in fieldStake) {
      if (fieldStake.hasOwnProperty(key)) {
        sum += parseInt(fieldStake[key]);
      }
    }

    this.setState({ fieldStake, errorStake, totalPoint: sum });
  };

  handleChangeSelect = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldMarket, errorStake } = this.state;
    fieldMarket[name] = value;
    errorStake[name] = "data not found";
    this.setState({ fieldMarket, errorStake });
    this.setState({
      errorStake: {
        ...this.state.errorStake,
        market: "",
      },
    });
  };

  placeBet = () => {
    this.props.dispatch(
      userActions.getGamesByGamesIdForUser({
        gameId: this.props.match.params.gameId,
      }),
    );
    let { users } = this.props;
    let { marketDetails,  getProfile, gameData } = users;
    let { list, jodiNumber } = gameData
      ? gameData
      : {};
    let {  closingTime } = list ? list : {};
    let { wallet } = getProfile ? getProfile : {};

    let { min, max } = marketDetails ? marketDetails : {};
    if (this.state.arrayofBets && this.state.arrayofBets.length == 0) {
      this.props.dispatch(alertActions.error("Attention", `No bets placed`));
      return;
    }
    if (this.state.arrayofBets.length !== 0) {
      for (let i = 0; i < this.state.arrayofBets.length; i++) {
        if (this.state.arrayofBets[i]?.amount < min) {
          this.props.dispatch(
            alertActions.error(
              "Attention",
              `Bet amount should be more than ${min}`,
            ),
          );
          return;
        } else if (this.state.arrayofBets[i]?.amount > max) {
          this.props.dispatch(
            alertActions.error(
              "Attention",
              `Bet amount should be less than ${max}`,
            ),
          );
          return;
        }
      }
    }




    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const CloseBetTime = closingTimes.format("hh:mm A");

    const getCurrentTime = () => {
      const now = moment();
      return now.format("hh:mm:A");
    };
    const currentTimeApis = getCurrentTime();
    const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetTime, "hh:mm A"),
    );

    if (!isBefore) {
      this.props.dispatch(
        alertActions.error(
          "Attention",
          `Bets are closed, Betting time is ${CloseBetTime}`,
        ),
      );
      return;
    }
    if (jodiNumber?.toString().length == 2) {
      this.props.dispatch(
        alertActions.error("Attention", `Result is Declared`),
      );
      return;
    }

    let { fieldStake, fieldMarket } = this.state;
    let { errorStake, totalPoint } = this.state;
    let bets = Object.keys(fieldStake).map((key) => ({
      betNumber: key,
      amount: parseInt(fieldStake[key]),
    }));
    let arryofbets = bets.filter(
      (e) => !isNaN(e.amount) && e.amount !== null && e.amount !== 0,
    );

    if (this.handleValid()) {
      let reqData = {
        marketId: this.props.match.params.marketId,
        gameId: this.props.match.params.gameId,
        bets: this.state.arrayofBets,
        isOpen: this.props.match.params.isOpen == "true" ? true : false,
        parentId: getProfile?.parentId || null,
        upline: getProfile?.upline || [],
      };

      this.props.dispatch(userActions.placeBet(reqData, this.props));
    }
  };

  handleValid = () => {
    let valid = true;
    let { fieldStake, fieldMarket } = this.state;
    let errorMessage = {};
    let bets = Object.keys(fieldStake).map((key) => ({
      betNumber: key,
      amount: parseInt(fieldStake[key]),
    }));

    // if (fieldMarket && Object.keys(fieldMarket).length == 0) {
    //   valid = false;
    //   errorMessage["market"] = "Select Market";
    // }
    if (this.state.arrayofBets && this.state.arrayofBets.length == 0) {
      valid = false;
      errorMessage["bet"] = "Bets not found";
    }
    // console.log("--------------errorStake",errorMessage)
    this.setState({ errorStake: { ...errorMessage } });

    return valid;
  };

  setinLocal = () => {
    if (
      this.state.fieldMarket &&
      Object.keys(this.state.fieldMarket).length > 0
    ) {
      // window.localStorage.setItem("isOpen", this.state.fieldMarket["isOpen"])
      this.setState({
        viewall: true,
      });
    }
  };

  setSelectedOption = (e) => {
    this.setState({ selectedOption: e?.value });
  };
  

  handleChangeSelect = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      fieldMarket: {
        ...prevState.fieldMarket,
        [name]: value,
      },
      errorStake: {
        ...prevState.errorStake,
        [name]: "data not found",
        market: "",
        betNumber: "",
      },
    }));
    // window.localStorage.setItem("isOpen",value)
  };

  handleChange = (e) => {
    e.preventDefault();
    let { users } = this.props;
    let { gameData } = users;
    let { list } = gameData
      ? gameData
      : {};
    let { max, min } = list ? list : {};
    let { name, value } = e.target;
    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = Number(value);

    this.setState({ fieldStake, errorStake });
  };
  addinArray = () => {
    const { amount } = this.state.fieldStake;
    let { users } = this.props;
    let betNumber = this.state.selectedOption;
    let { gameData } = users;
    let { list } = gameData
      ? gameData
      : {};
    let { max, min } = list ? list : {};
    // Checking if amount is within valid range
    if (Number(amount) > max) {
      this.props.dispatch(
        alertActions.warn(`Attention`, `Max bid amount is ${max}`),
      );
      return;
    }
    if (Number(amount) < min) {
      this.props.dispatch(
        alertActions.warn("Attention", `Min bid amount is ${min}`),
      );
      return;
    }
    if (
      betNumber !== undefined &&
      amount !== undefined &&
      this.state.selectedOption !== null
    ) {
      const betIndex = this.state.arrayofBets.findIndex(
        (bet) => bet.betNumber === betNumber,
      );

      if (betIndex !== -1) {
        this.setState((prevState) => {
          const updatedBets = [...prevState.arrayofBets];
          updatedBets[betIndex] = { ...updatedBets[betIndex], amount: amount };
          return {
            arrayofBets: updatedBets,
            fieldStake: {},
          
          };
        });
      } else {
        this.setState((prevState) => ({
          arrayofBets: [
            ...prevState.arrayofBets,
            { betNumber: betNumber, amount: amount },
          ],
          fieldStake: {},
      
        }));
      }
    
    } else {
    }
  };
  letOpenModel = () => {
    this.setState({ isModelOpen: true });
  };
  letOpenModelClose = () => {
    this.setState({ isModelOpen: false });
  };

  handleDelete = (betNumber) => {
    this.setState((prevState) => ({
      arrayofBets: prevState.arrayofBets.filter(
        (bet) => bet.betNumber !== betNumber,
      ),
    }));
  };

  handleNewChange = (e) => {
    this.props.dispatch(
      userActions.getGamesByGamesIdForUser({
        gameId: this.props.match.params.gameId,
      }),
    );

    if (!this.state.fieldMarket["isOpen"]) {
      alert("Select Market Status");
      return;
    }
    let { users } = this.props;
    let { gameData } = users;
    let { list, jodiNumber} = gameData
      ? gameData
      : {};
    let {closingTime, betClosingTime } = list ? list : {};





    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const CloseBetTime = closingTimes.format("hh:mm A");

    const getCurrentTime = () => {
      const now = moment();
      return now.format("hh:mm:A");
    };
    const currentTimeApis = getCurrentTime();

    const closeCloseBidTime = moment
      .duration(betClosingTime && betClosingTime, "seconds")
      .asMinutes();
    const MinutesBeforeClosing = closingTimes
      .clone()
      .subtract(closeCloseBidTime, "minutes");
    const CloseBetMinutesBeforeClosing = MinutesBeforeClosing.format("hh:mm A");
    const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(
      moment(CloseBetMinutesBeforeClosing, "hh:mm A"),
    );
    // const isBefore = moment(currentTimeApis, "hh:mm A").isBefore(moment(CloseBetTime, "hh:mm A"));

    if (!isBefore) {
      this.props.dispatch(
        alertActions.error(
          "Attention",
          `Bets are closed, Betting time is ${CloseBetTime}`,
        ),
      );
      return;
    }
    if (jodiNumber?.toString().length == 2) {
      this.props.dispatch(
        alertActions.error("Attention", `Result is Declared`),
      );
      return;
    }

    const { name, value } = e.target;
    const { arrayofBets } = this.state;

 


    if (value.length > 5) {
      return;
    }

    const index = arrayofBets.findIndex((bet) => bet.betNumber === name);

    if (value.trim() === "") {
      // Remove bet if value is empty
      const updatedBets = arrayofBets.filter((bet) => bet.betNumber !== name);
      this.setState({ arrayofBets: updatedBets });
    } else if (index !== -1) {
      // Update existing bet with new amount
      const updatedBets = [...arrayofBets];
      updatedBets[index] = { ...updatedBets[index], amount: value };
      this.setState({ arrayofBets: updatedBets });
    } else {
      // Add new bet if not found
      const newBet = { betNumber: name, amount: value };
      this.setState((prevState) => ({
        arrayofBets: [...prevState.arrayofBets, newBet],
      }));
    }
  };
  handlePoints = (event) => {
    const value = event.target.value;

    if (value == "") {
      this.setState({ Point: null });
    } else {
      this.setState({ Point: value });
    }
  };

  handlePoints2 = (event) => {
    const value = event.target.value;
    if (value.length >= 2) {
      return;
    }

    if (value == "") {
      this.setState({ Ank: null });
    } else {
      this.setState({ Ank: value });
    }
  };

  handlePana = (e, selectedOption) => {
    const isChecked = e.target.checked; // Check if the checkbox is checked

    this.setState(prevState => {
      const updatedPana = {
        ...prevState.Pana,
        ...(isChecked ? { [selectedOption]: prevState.option[selectedOption] } : { [selectedOption]: undefined }), // Add or remove value based on checked status
      };

      // Clean up undefined values from Pana
      const filteredPana = Object.fromEntries(Object.entries(updatedPana).filter(([_, v]) => v !== undefined));

      return {
        Pana: filteredPana
      };
    });
  };



  handleAdd = () => {
    let { users } = this.props;
    let { marketDetails } = users;
    const { Point, Pana, Ank } = this.state;

    // Check if Point is valid
    if (Point) {
      // If Pana is empty, reset arrayofBets to an empty array
      if (Object.keys(Pana).length === 0) {
        this.setState({
          arrayofBets: [], // Remove all bets
        });
        console.log("Pana is empty. All bets removed.");
        return; // Exit the function
      }

      // Create new bets based on the current state of Pana
      const newBets = Object.values(Pana).flatMap(betValueArray =>
        betValueArray.map(number => ({
          betNumber: number,
          amount: Point, // Use the Point as the amount
        }))
      );

      // Helper function to calculate the sum of digits of a number
      const sumOfDigits = (num) => {
        const sum = num.toString().split('').reduce((sum, digit) => sum + parseInt(digit, 10), 0);
        return sum.toString().padStart(2, '0'); // Pad the sum with '0' if it's a single digit
      };

      // Filter newBets to only include those where the sum of digits equals Ank
      const filteredNewBets = newBets.filter(bet => sumOfDigits(bet.betNumber)[1]?.toString() === Ank?.toString());

      console.log("Filtered New Bets:", filteredNewBets);

      // Directly update arrayofBets with the filtered new bets
      this.setState({
        arrayofBets: filteredNewBets, // Update with the new bets only
      });

      console.log("Updated Bets:", filteredNewBets);
    }
  };






  render() {

    let { users } = this.props;
    let { marketDetails, marketLoading } = users;
    let {  gameData, getProfile } = users;
    let { wallet, blockedNumbers } = getProfile ? getProfile : {};
    let {  list } = gameData
      ? gameData
      : {};
    let { brandGame,openTime ,closingTime,betOpenTime,betClosingTime} = list
      ? list
      : {};

      const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const closeCloseBidTime = moment
      .duration(betClosingTime && betClosingTime, "seconds")
      .asMinutes();
    const closeOpenBidTime = moment
      .duration(betOpenTime && betOpenTime, "seconds")
      .asMinutes();

    const MinutesBeforeClosing = closingTimes
      .clone()
      .subtract(closeCloseBidTime, "minutes");
    const MinutesBeforeOpening = openingTime
      .clone()
      .subtract(closeOpenBidTime, "minutes");

    //formate
    const CloseBetMinutesBeforeClosing = MinutesBeforeClosing.format("hh:mm A");
    const CloseBetMinutesBeforeOpening = MinutesBeforeOpening.format("hh:mm A");



    const totalAmount =
      this.state.arrayofBets && this.state.arrayofBets.length > 0
        ? this.state.arrayofBets.reduce(
          (total, bet) => total + parseInt(bet.amount),
          0,
        )
        : 0;


    let selectedMarketBlock =
      blockedNumbers &&
      blockedNumbers.length > 0 &&
      blockedNumbers.filter(
        (e) => e.marketId == this.props.match.params.marketId,
      );
    let arrayToblock =
      selectedMarketBlock &&
      selectedMarketBlock.length > 0 &&
      selectedMarketBlock[0] &&
      selectedMarketBlock[0]?.numbers &&
      selectedMarketBlock[0]?.numbers?.map((e) => e?.numbers);
    let flatarrydatatoBlock =
      (arrayToblock &&
        arrayToblock.length > 0 &&
        arrayToblock.flat(Infinity)) ||
      [];
 
    return (
      <div className="w-full h-full relative overflow-y-hidden bg-gray-200">
        <Loader loading={marketLoading} />
        <div className="bgcolor flex items-center justify-center text-white text-3xl h-16 rounded-b-3xl relative">
          <IoArrowBack
            className="cursor-pointer"
            onClick={() => {
              this.setState({ option: [] });  // Reset the option array in state
              this.props.history.goBack();  // Navigate back to the previous page
            }}
          />

          <div className="uppercase  text-base ">

            <CustomSlider title={`${this.props.match.params.gameName} - Sp Motor`} />
          </div>
          <div className="text-sm"><HeaderWallet /></div>
        </div>

        <div className="w-full p-2  space-y-5">
          <div className="   w-full  rounded-lg space-y-5">
            <div className="flex  justify-center gap-2  items-center">
              <div className="flex  justify-center flex-col gap-2 w-full  items-center">
                {(brandGame == undefined || brandGame !== true) && <div className=" justify-between flex items-center w-full">
                  <p className="w-full"
                  >Game Type :</p>
                  <div className=" bg-white rounded shadow border w-[97%]  text-center p-1.5">{this.props.match.params.isOpen == "true" ? "OPEN" : "CLOSE"}</div>
                </div>}

                <div className=" justify-between flex items-center w-full">
                  <div className=" flex-1  text-base"> Enter Ank :</div>
                  <div className="flex flex-1 flex-col justify-center  p-1 items-center">
                    <div className="flex items-center  w-full justify-around rounded-lg space-x-5">
                      <input
                        placeholder="Ank"
                        type="number"
                        value={this.state.Ank || ""}
                        onChange={(e) => this.handlePoints2(e)}
                        className=" w-full  text-center border p-1.5 rounded border-gray-300"
                        onKeyDown={(e) => {
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>


                <div className=" justify-between flex items-center w-full">
                  <div className=" flex-1  text-base"> Enter Points :</div>
                  <div className="flex flex-1 flex-col justify-center p-1 items-center">
                    <div className="flex items-center  w-full justify-around rounded-lg space-x-5">
                      <input
                        placeholder="Point"
                        type="number"
                        value={this.state.Point || ""}
                        onChange={(e) => this.handlePoints(e)}
                        className=" w-full  text-center border border-gray-300 rounded p-1.5"
                        onKeyDown={(e) => {
                          // Prevent typing a negative sign
                          if (e.key === '-') {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="justify-between flex items-center w-full">
                  <div className="flex-1 text-base">Select Pana:</div>
                  <div className="flex flex-1 flex-col justify-center rounded-lg text-black p-1 items-center">
                    <div className="flex items-center w-full justify-around rounded-lg space-x-5">
                      {this.state.option&&Object.keys(this.state.option)?.length>0&&Object.keys(this.state.option)?.map((key, index) => (
                        <div key={index} className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            id={`option-${index}`}  // Unique ID for each checkbox
                            value={key}
                            onChange={(e) => this.handlePana(e, key)}  // Handle checkbox changes for keys
                            className="form-checkbox"
                          />
                          <label htmlFor={`option-${index}`} className="text-base">
                            {key}
                          </label>
                        </div>
                      ))}
                    </div>

                  </div>
                </div>



                <div className=" justify-between flex items-center w-full">
                  <div className=" flex-1  text-base"> </div>
                  <div className="flex flex-1 flex-col justify-center rounded bgcolor text-white  p-1.5 items-center">
                    <div className="flex items-center  w-full justify-around rounded-lg space-x-5">
                      <button
                        onClick={() => this.handleAdd()}
                        className=" w-full "
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" h-[57vh] overflow-hidden overflow-y-auto">
              {this.state.arrayofBets &&
                this.state.arrayofBets && (
                  <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                      <tr className="border-b bgcolor text-white border-gray-300 text-sm">
                        <th className="py-2 px-4 text-left">Digit</th>
                        <th className="py-2 px-4 text-left">Point</th>
                        <th className="py-2 px-4 text-left">{brandGame?"Time":"Type"}</th>
                        <th className="py-2 px-4 text-left">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.arrayofBets &&
                        this.state.arrayofBets.length > 0 ? (
                        this.state.arrayofBets.map((bet, index) => (
                          <tr
                            key={index}
                            className="border-b border-gray-300"
                          >
                            <td className="py-2 px-4">{bet.betNumber}</td>
                            <td className="py-2 px-4">{bet.amount}</td>
                            <td className="py-2 px-4">
{/* ?                              {this.props.match.params.isOpen == "true" ? "OPEN" : "CLOSE"} */}
                          {brandGame ? CloseBetMinutesBeforeOpening : (this.props.match.params.isOpen === "true" ? "OPEN" : "CLOSE")}

                            </td>
                            <td className="py-2 px-4">
                              <button
                                onClick={() =>
                                  this.handleDelete(bet.betNumber)
                                }
                                className="text-red-500  py-1 px-3 rounded"
                              >
                                <IoMdTrash />
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="py-2 px-4 text-center">
                            No bets available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                )}
            </div>

            <div className=" absolute bottom-0 left-0 w-full">
              {this.state.arrayofBets &&
                this.state.arrayofBets && (
                  <div className="  w-full border-t-2 border-[#C11C84]">
                    <div className="grid grid-cols-2 w-full p-1 py-3 bg-white -lg borer-2">

                      <div>
                        <SubmitTotalData totalAmount={totalAmount > 0 && totalAmount ? totalAmount : '0'}
                          arrayofBets={this.state.arrayofBets && this.state.arrayofBets?.length} />
                      </div>
                      <button
                        onClick={() => this.letOpenModel()}
                        className="py-2 px-4 ml-4 bgcolor hover:bg-[#A0186D] text-white font-semibold rounded-lg shadow-md transition-colors duration-200"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                )}
            </div>

            {this.state.viewall && (
              <div>
                <div className="  text-black pb-14 px-1  gap-4 border-t h-[50vh] overflow-y-scroll overflow-hidden border-white pt-2  flex  flex-col">
                  <div className="pb-2  flex-col   flex  justify-center items-center gap-1">
                    {marketDetails?.numList &&
                      marketDetails.numList.map((elem, index) => {
                        return (
                          <div key={index}>
                            <div className="bgblue  text-white md:text-sm text-xs  mb-1 p-2 rounded justify-center items-center flex">
                              {index} House
                            </div>
                            <div className="grid xl:grid-cols-12 lg:grid-cols-7  md:grid-cols-8 grid-cols-6 gap-1">
                              {/* <div className='grid xl:grid-cols-10 lg:grid-cols-7  md:grid-cols-8 grid-cols-5 gap-1'> */}
                              {elem &&
                                Array.isArray(elem) &&
                                elem.map((e) => {
                                  if (
                                    !flatarrydatatoBlock?.includes(
                                      e?.toString(),
                                    )
                                  ) {
                                    return (
                                      <div
                                        key={e}
                                        className="font-bold bgblue rounded-lg p-2 flex flex-col m-0.5 justify-between items-center"
                                      >
                                        <div className="text-white flex justify-center items-center text-sm">
                                          {e}
                                        </div>
                                        <input
                                          name={e}
                                          value={
                                            this.state.arrayofBets.find(
                                              (bet) => bet.betNumber == e,
                                            )?.amount || ""
                                          }
                                          onChange={this.handleNewChange}
                                          className="md:w-12 w-10 md:text-sm text-xs rounded-md outline-none"
                                          type="number"
                                        />
                                      </div>
                                    );
                                  }
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>


          <ConfirmationModel
            type={this.props.match.params.isOpen == "true" ? "OPEN" : "CLOSE"}
            isModelOpen={this.state.isModelOpen}
            arrayOfBets={this.state.arrayofBets}
            walletAmount={wallet}
            letOpenModelClose={this.letOpenModelClose}
            placeBet={this.placeBet}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(SPDPTP);

import { withRouter } from 'react-router-dom';
import { bankActions, userActions } from '../../_actions';
import { connect, useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoCaretBackCircleSharp } from "react-icons/io5";
import { IoBarChartSharp } from "react-icons/io5";
import { useParams } from 'react-router';
import Loader from '../../components/Loader/Loader';
import { useEffect } from 'react';
const KolkataFatafatChart = ({ dispatch, props }) => {
  const history = useHistory();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { appSettingData, gameList, kchart, loading } = users;
  let parmas = useParams()
  useEffect(() => {

    dispatch(bankActions.calanderForKolkataFatafat({ brandId: parmas?.id }));
  }, [parmas])



  return (
    <>
      <div className='bg-[#EEEEEE] h-screen  w-full '>
        <div className='flex space-x-1 p-1 h-[90vh]  overflow-y-scroll'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <Loader loading={loading} />
          <div className='w-full'>
            <IoCaretBackCircleSharp onClick={() => history.goBack()} className=' text-3xl text-[#3e44d5] cursor-pointer m-1' />

            <div className=' bg-white p-5 flex flex-col gap-4 rounded-md mb-10 '>



              <div>
                {/* <a className=' flex bgblue justify-center items-center w-40 mx-auto mb-3 py-2 px-1 text-white font-bold rounded-md' href='#top' id='bottom'> GO TO BOTTOM  </a> */}
                <div className='overflow-hidden overflow-x-auto'>


                  {
                    kchart && kchart.length > 0 && kchart.map((elem, i) => {

                      return (
                        <div>
                          <div className=' bgblue  justify-center items-center  text-white text-center  '>{elem?.date}</div>
                          <div className=' border-2 flex '>
                            {elem && elem?.results && elem?.results.length > 0 && elem?.results.map((e) => {
                              return (
                                <div className=' border flex-1 items-center flex flex-col justify-center '>

                                  <div className=' '>{e?.openNumber}</div>
                                  <div className=' '>{e?.jodiNumber[0]}</div>
                                </div>
                              )
                            })}
                          </div>

                        </div>
                      )
                    })
                  }


                </div>
                {/* 
                <a className=' flex bgblue justify-center items-center w-40 mx-auto mt-3 py-2 px-1 text-white font-bold rounded-md' href='#bottom' id='top'>
                  GO TO Top
                </a> */}
              </div>


            </div>




          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(KolkataFatafatChart));
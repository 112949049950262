import { gameResult } from "../_constants";

export function gameOfResult(state = {}, action) {
    switch (action.type) {
        case gameResult.GAME_RESULT_LIST_REQUEST:
            return {
               ...state,
                loading: true
            };
        case gameResult.GAME_RESULT_LIST_SUCCESS:
            return {
               ...state,
                loading: false,
                gameResult: action.gameResult
            };
        case gameResult.GAME_RESULT_LIST_FAILURE:
            return {
               ...state,
                loading: false,
                error: action.error
            };


            case gameResult.GAME_RESULT_REQUEST:
                return {
                   ...state,
                    loading: true
                };
            case gameResult.GAME_RESULT_SUCCESS:
                return {
                   ...state,
                    loading: false,
                    gameResultData:action?.user
                };
            case gameResult.GAME_RESULT_FAILURE:
                return {
                   ...state,
                    loading: false,
                    error: action.error
                };
        default:
            return state;
    }
}
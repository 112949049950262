import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { userService } from '../../_services';
import { IoArrowBackOutline } from 'react-icons/io5'
import { gameResultAction } from '../../_actions';

function GameResult() {
  const selector = useSelector(state => state)
  const dispatch = useDispatch()

  console.log("selector_________", selector?.gameOfResult?.gameResultData?.gameResults?.data)
  const today = new Date();
  const year = today.getFullYear();
  const month = ('0' + (today.getMonth() + 1)).slice(-2);
  const day = ('0' + today.getDate()).slice(-2);
  const dateString = `${year}-${month}-${day}`;
  const [selectedDate, setSelectedDate] = useState(dateString);

  useEffect(() => {

    dispatch(gameResultAction.gameResults())
  }, [])

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    let data = {
      filterDate: selectedDate,
      JodiGame: '',
      brandGame: ''
    }
    dispatch(gameResultAction.gameResults(data))
  };

  const goBack = () => {
    window.history.back()
  }


  const formatJodiNumber = (jodiNumber) => {
    if (jodiNumber && jodiNumber.length === 2) {
      return jodiNumber.split('').join(' ');
    } else if (jodiNumber && jodiNumber.length === 1) {
      return `${jodiNumber} *`;
    } else {
      return '* *';
    }
  };


  return (
    <div className='bg-white text-black'>
      <div className='h-16 w-full border bgcolor rounded-b-3xl flex justify-start items-center px-4 gap-3'>
        <IoArrowBackOutline className='text-white w-6 h-6 cursor-pointer' onClick={goBack} />
        <div className='font-semibold text-white uppercase' > Market Result History</div>
      </div>
      <div className='flex justify-start space-x-3 items-center text-sm p-4'>
        <p>Select Date:</p>
        <input type='date' value={selectedDate} onChange={handleDateChange} className='bg-white px-3 py-0.5 uppercase rounded-full appearance-none border border-gray-400 text-sm' />
      </div>

      <div className='px-3 overflow-y-auto h-screen pb-36'>
        {selector?.gameOfResult?.gameResultData?.gameResults?.data && selector?.gameOfResult?.gameResultData?.gameResults?.data.length > 0 ? selector?.gameOfResult?.gameResultData?.gameResults?.data.map((ele, id) => (
          <div key={id} className="border border-gray-300 p-3 mb-2 rounded-lg flex justify-between items-center">
            <h3 className="text-lg font-semibold capitalize">{ele.gameId.name}</h3>
            <div>
              {ele.openNumber || "***"}-{formatJodiNumber(ele?.jodiNumber)}-{ele.closeNumber || '***'}
            </div>
          </div>
        )) : (<div>No Data Found</div>)}
      </div>
    </div>
  )
}

export default GameResult

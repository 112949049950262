import React, { useEffect } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useSelect } from 'react-select-search'
import { userActions } from '../../_actions'
import moment from 'moment'

function TimeTable() {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const handleBack = () => {
    window.history.back()
  }

  let { users } = selector ? selector : {}
  let { appSettingData, gameList, loading } = users;
  // console.log("gameList_____",gameList);


  useEffect(() => {
    let data =
    {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 100
    }
    // dispatch(userActions.getGamesList(data));
    dispatch(userActions.getGamesList(data));
  }, [])
  return (
    <div className='bg-white text-black'>
      <div className='h-16 w-full border bgcolor rounded-b-3xl flex justify-between items-center place-items-end px-4 gap-3'>
        <div className='flex justify-start place-items-center gap-2'>
          <IoArrowBackOutline className='text-white mb-1 text-lg cursor-pointer' onClick={handleBack} />
          <div className='  font-semibold text-white cursor-pointer' >Time Table</div>
        </div>
      </div>
      <div className='px-3 pt-2'>
        <div className='grid grid-cols-3 bgcolor border rounded text-white p-2 my-1 text-xs font-semibold'>
          <p className='text-left capitalize'>Game Name</p>
          <p className='text-right'>Open Time</p>
          <p className='text-right'>Close Time</p>
        </div>
      </div>
      <div className='p-3 h-screen overflow-y-auto pb-32'>
        {gameList && gameList.length > 0 && gameList.map((ele, id) => {
          const openingTime = moment()
            .hours(parseInt(ele && ele.openTime / 3600))
            .minutes(
              parseInt(
                (ele && ele.openTime % 3600) / 60,
              ),
            )
            .seconds(parseInt(ele && ele.openTime % 60))
            .milliseconds(0);
          const closingTime = moment()
            .hours(
              parseInt(ele && ele.closingTime / 3600),
            )
            .minutes(
              parseInt(
                (ele && ele.closingTime % 3600) / 60,
              ),
            )
            .seconds(
              parseInt(ele && ele.closingTime % 60),
            )
            .milliseconds(0);
          const OpenBetTime = openingTime.format("hh:mm A");
          const CloseBetTime = closingTime.format("hh:mm A");

          return (
            <>
              <div key={id} className='grid grid-cols-3 bg-gray-100 border rounded text-black p-2 my-1 text-xs'>
                <p className='text-left text-xs font-medium capitalize'>{ele ? ele?.name : '-'}</p>
                <p className='text-right'>{OpenBetTime}</p>
                <p className='text-right'>{CloseBetTime}</p>
              </div>
            </>
          )
        })}

      </div>
    </div>
  )
}

export default TimeTable

import { starlineConstant } from "../_constants";
import { userService } from '../_services';
import { alertActions } from './';



export const starlineAction = {
    getBetsListStarLine,
    calanderForStarLine
}

function getBetsListStarLine(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.getBetsListStarLine(data)

            .then(
                user => {
                    dispatch(success(user));

                },
                error => {

                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: starlineConstant.GET_STARLINE_BID_LIST_REQUEST, user } }
    function success(user) { return { type: starlineConstant.GET_STARLINE_BID_LIST_SUCCESS, user } }
    function failure(error) { return { type: starlineConstant.GET_STARLINE_BID_LIST_FAILURE, error } }
}



function calanderForStarLine(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.calanderForStarLine(data)

            .then(
                user => {
                    dispatch(success(user));

                },
                error => {

                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: starlineConstant.GET_STARLINE_CHART_LIST_REQUEST, user } }
    function success(user) { return { type: starlineConstant.GET_STARLINE_CHART_LIST_SUCCESS, user } }
    function failure(error) { return { type: starlineConstant.GET_STARLINE_CHART_LIST_FAILURE, error } }
}
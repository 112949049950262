import { JackpotConstant } from "../_constants";
import { userService } from '../_services';
import { alertActions } from './';

export const jackpotAction = {
    getBetsListJackPot
}

function getBetsListJackPot(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.getBetsListJackPot(data)

            .then(
                user => {
                    dispatch(success(user));
                
                },
                error => {

                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: JackpotConstant.GET_JACKPOT_BET_LIST_REQUEST, user } }
    function success(user) { return { type: JackpotConstant.GET_JACKPOT_BET_LIST_SUCCESS, user } }
    function failure(error) { return { type: JackpotConstant.GET_JACKPOT_BET_LIST_FAILURE, error } }
}
import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FcComboChart } from "react-icons/fc";
import { MdClose } from "react-icons/md";



const ChartModal = ({ onClose, idForChart }) => {

  // console.log("idForChartidForChart",idForChart);
  const history = useHistory();

  return (
    <div>
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm flex justify-center items-center">
        {/* Modal Content */}
        <div className="bg-white rounded-lg p-4 w-80 shadow-lg relative">

          <div onClick={() => onClose()} className="flex justify-between">
            <h2 className="font-bold text-lg text-center pb-4 capitalize textcolor">{idForChart?.name}</h2>
            <MdClose className='h-6 w-6 bg-gray-100 p-1 hover:bg-red-500 hover:text-white rounded-full cursor-pointer' />
          </div>

          <div className="flex justify-between place-items-center space-x-2">
            <button onClick={() => history.push(`/app/chartjodi/${idForChart?._id}/${idForChart?.name}`)} className="flex justify-start items-center gap-2 bg-gray-200 text-black font-semibold text-base px-2 shadow py-1 rounded hover:bg-brown-700 transition" >
              Jodi Chart <FcComboChart className='w-6 h-6' />
            </button>
            <button onClick={() => history.push(`/app/charts/${idForChart?._id}/${idForChart?.name}`)} className="flex justify-start items-center gap-2 bg-gray-200 text-black font-semibold px-2 shadow text-base py-1 rounded hover:bg-brown-700 transition">
              Panel Chart <FcComboChart className='w-6 h-6' />
            </button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ChartModal
import React from 'react'

const CustomSlider = ({ title }) => {
    return (
        <div>
            <div className="uppercase text-base overflow-hidden whitespace-nowrap w-64">
                <div className="inline-block animate-scroll">
                    {title}
                </div>
                <style jsx>{`
        @keyframes scroll {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
        .animate-scroll {
          animation: scroll 10s linear infinite;
        }
      `}</style>
            </div>
        </div>
    )
}

export default CustomSlider
import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "react-select-search";
import { starlineAction } from "../../_actions";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Loader from "../../components/Loader/Loader";
import { FaCheckCircle, FaHashtag, FaHourglassHalf, FaTags } from "react-icons/fa";


function IndianStarlineBidHiatory() {
  const selector = useSelector((state) => state);
  // console.log("sele_____", selector);

  const dispatch = useDispatch();
  const [offset, setoffset] = useState(0);
  const [size, setsize] = useState(25);
  const [page, setpage] = useState(1);
  const [index, setindex] = useState(0);
  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  useEffect(() => {
    let data = {
      gameId: "",
      fromDate: fromDate,
      toDate: toDate,
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: page,
      size: size,
    };
    dispatch(starlineAction.getBetsListStarLine(data));
  }, [page, toDate]);

  const handleChange = async (e) => {
    if (page !== 1) {
      setpage(1);
    }
    setfromDate(e.target.value);
    settoDate(e.target.value);
  };

  let handlePageClick = (data) => {
    console.log("data  ", data);
    setindex(data.selected);
    let offset = Math.ceil(data.selected * size);
    setoffset(offset);
    setpage(data.selected + 1);
  };
  const goBack = () => {
    window.history.back();
  };
  let { starline } = selector ? selector : {};
  let { getStarlineBetList, getStarlineBetTotal } = starline ? starline : {};

  return (
    <>


      <Loader Loader={selector?.starline?.loading} />
      <div className="bg-white text-black min-h-screen">
        <div className="h-16 w-full border bgcolor rounded-b-3xl flex justify-start items-center place-items-end px-4 gap-3">
          <IoArrowBackOutline
            className="text-white mb-1 text-lg cursor-pointer"
            onClick={goBack}
          />
          <div className="  font-semibold text-white cursor-pointer uppercase">
            {" "}
            Starline bid History
          </div>
        </div>


        <div className="flex justify-start space-x-3 items-center text-sm p-4">
          <p className="">Select Date:</p>
          <input type="date" onChange={(e) => handleChange(e)} className="px-3 py-0.5 uppercase rounded-full appearance-none border border-gray-400 text-sm" />
        </div>


        <div className="p-4 overflow-hidden overflow-y-auto h-[90vh]">
          <div className="">
            <div className="flex flex-col gap-5  h-full ">
              {getStarlineBetList &&
                getStarlineBetList.length > 0 &&
                getStarlineBetList.map((element, index) => {
                  return (
                    // <div
                    //     key={index}
                    //     className="flex flex-col bg-white text-xs  border-gray-500 rounded-md border p-4 "
                    // >
                    //     <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                    //         <div>S.No.</div> {offset + index + 1}
                    //     </div>
                    //     <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                    //         <div>Market</div> {element?.marketId?.name || "-"}
                    //     </div>
                    //     <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                    //         <div>Game</div> {element?.gameId?.name || "-"}
                    //     </div>
                    //     <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                    //         <div>NUMBER</div> {element?.betNumber || "-"}
                    //     </div>
                    //     <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                    //         <div>Point</div> {element?.amount || "-"}
                    //     </div>
                    //     <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                    //         <div>BID TYPE</div> {element?.isOpen ? "Open" : "Close"}
                    //     </div>
                    //     <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                    //         <div>Date</div>
                    //         {moment(new Date(parseInt(element.createdAt)))
                    //             .utcOffset("+05:30")
                    //             .format("DD-MM-YYYY hh:mm A")}
                    //     </div>
                    //     <div className="flex w-full justify-between whitespace-nowrap border-b-2 border-gray-300 mb-1 uppercase">
                    //         <div>Profit/Loss</div>
                    //         {element?.betStatus === 0 && (
                    //             <div className="text-yellow-500">Pending</div>
                    //         )}
                    //         {element?.betStatus === 1 && (
                    //             <div className="text-green-800">
                    //                 +{element?.winAmount}
                    //             </div>
                    //         )}
                    //         {element?.betStatus === 2 && (
                    //             <div className="text-red-700">
                    //                 -{element?.lossAmount}
                    //             </div>
                    //         )}
                    //     </div>
                    // </div>

                    <div className="max-w-sm w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden border border-gray-300">

                      <div className="bgcolor text-white text-center py-2">
                        <p className="text-base font-semibold">
                          {moment(new Date(parseInt(element.createdAt)))
                            .utcOffset("+05:30")
                            .format("hh:mm A")}
                          ( {element?.marketId?.name || "-"}, {element?.isOpen ? "Open" : "Close"}) </p>
                      </div>


                      <div className="p-4">

                        <div className="flex justify-center place-items-center gap-4">
                          <p>Game Name:</p>
                          <p className="text-sm capitalize">{element?.gameId?.name}</p>
                        </div>

                        <div className="mt-4 grid grid-cols-2 text-center gap-2">
                          <div>
                            <p className="text-xs text-gray-500">Point</p>
                            <p
                              className="text-lg font-semibold textcolor"
                            >
                              {element?.amount || "-"}
                            </p>
                          </div>
                          <div>
                            <p className="text-xs text-gray-500">Digit</p>
                            <p className="text-lg font-semibold textcolor">{element?.betNumber || "-"}</p>
                          </div>

                        </div>

                        {/* Transaction time */}
                        <p className="text-sm text-gray-700 mt-4">
                          <span className="text-black">Transaction Time:</span><span className="text-gray-400 px-2">

                            {moment(new Date(parseInt(element.createdAt)))
                              .utcOffset("+05:30")
                              .format("DD-MM-YYYY (ddd)")}
                          </span>
                        </p>

                        {/* Winning message */}
                        <p className="text-green-600 text-center mt-4">
                          {element?.betStatus === 0 && <div className="text-yellow-500">Pending</div>}
                          {element?.betStatus === 1 && <div className="text-green-800">YOu Won Rs. +{element?.winAmount}  🤩</div>}
                          {element?.betStatus === 2 && <div className="text-red-700">Better Luck Next Time!!! 😔 -{element?.lossAmount}</div>}
                        </p>
                      </div>
                    </div>

                    // <div key={index} className="bg-white text-sm shadow-md rounded-lg overflow-hidden transition transform hover:shadow-xl hover:scale-105   duration-300 ease-in-out">
                    //   <div className="bg-gradient-to-r from-pink-500 to-pink-700 p-2 flex justify-between items-center">
                    //     <p className="text-white font-bold text-lg">
                    //       {offset + index + 1}
                    //     </p>
                    //     <p className="text-white text-sm">
                    //       {moment(new Date(parseInt(element.createdAt)))
                    //         .utcOffset("+05:30")
                    //         .format("DD-MM-YYYY hh:mm A")}
                    //     </p>
                    //   </div>

                    //   <div className="p-2">
                    //     <div className="flex justify-between items-center mb-1">
                    //       <div className="flex items-center">
                    //         <FaTags className="text-pink-600 mr-2" />
                    //         <p className="text-gray-800 font-semibold">{element?.marketId?.name || "-"}</p>
                    //       </div>
                    //       <p className="text-blue-700 font-bold "><span>₹</span>{element?.amount || "-"}</p>
                    //     </div>

                    //     <div className="bg-gray-50 p-2 rounded-lg mb-1 text-center">
                    //       <p className="text-lg font-semibold text-pink-600">Game Name</p>
                    //       <p className="text-gray-700 capitalize">{element?.gameId?.name || "-"}</p>
                    //     </div>

                    //     <div className="flex justify-between items-center mb-1">
                    //       <div>
                    //         <p className="font-semibold text-gray-800">Bid Type</p>
                    //         <p className="text-blue-700">{element?.isOpen ? "Open" : "Close"}</p>
                    //       </div>
                    //       <div className="text-center">
                    //         <div className="text-gray-700 font-semibold flex items-center mb-1">
                    //           <FaHashtag className="text-pink-600 mr-2" /> Number
                    //         </div>
                    //         <p className="text-blue-700 font-bold t">{element?.betNumber || "-"}</p>
                    //       </div>
                    //     </div>

                    //     <div className="flex justify-between items-center bg-gray-100 p-3 rounded-lg">
                    //       <div>
                    //         {element?.betStatus === 0 && (
                    //           <div cla
                    //       </div>
                    //       <div>
                    //         {element?.betStatus === 1 && (
                    //           <div className="text-green-600 font-bold text-base">
                    //             +{element?.winAmount}
                    //           </div>
                    //         )}
                    //         {element?.betStatus === 2 && (
                    //           <div className="text-red-600 font-bold text-base">
                    //             -{element?.lossAmount}
                    //           </div>
                    //         )}
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>betStatus === 2 && (
                    //           <div className="text-red-600 flex items-center">
                    //             <img src="/loss_11332393.png" alt="loss" className="w-5 h-5 mr-2" />
                    //             Loss
                    //           </div>
                    //         )}
                    //       </div>
                    //       <div>
                    //         {element?.betStatus === 1 && (
                    //           <div className="text-green-600 font-bold text-base">
                    //             +{element?.winAmount}
                    //           </div>
                    //         )}
                    //         {element?.betStatus === 2 && (
                    //           <div className="text-red-600 font-bold text-base">
                    //             -{element?.lossAmount}
                    //           </div>
                    //         )}
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                  );
                })}

              <nav className="flex justify-end mt-4 w-full">
                {getStarlineBetTotal && getStarlineBetTotal > size ? (
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={getStarlineBetTotal / size}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-cls"}
                    activeClassName={"active"}
                  />
                ) : null}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndianStarlineBidHiatory;

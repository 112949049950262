import React from "react";
import { connect } from "react-redux";

import { FaCheckCircle, FaDollarSign, FaHashtag, FaHourglassHalf, FaRegCalendar, FaTags, FaTimesCircle } from "react-icons/fa";
import { HiOutlineChevronRight } from "react-icons/hi2";
import { RxCountdownTimer } from "react-icons/rx";
import { betActions, userActions } from "../../../_actions";
import Loader from "../../../components/Loader/Loader";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { IoArrowBackOutline } from "react-icons/io5";
import { RiFilterFill } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";




class BidHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      isOpen: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
    };
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),
      // "keyWord": "",
      sortBy: "",
      sortOrder: "",
      pageNo: this.state.pageNo,
      size: this.state.size,
    };

    this.props.dispatch(betActions.betHistory(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData = {
      // "keyWord": "",
      pageNo: this.state.pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 200,
    };

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }

  getBatHistory = () => {
    this.setState({ pageNo: 1 }, () => {

      let data = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        gameId: this.state.sportsId,
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
      };

      this.props.dispatch(betActions.betHistory(data));
    })
  };

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  };

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };

  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  };

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        // "keyWord": "",
        gameId: this.state.sportsId,
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
      };
      this.props.dispatch(betActions.betHistory(pagination));
    });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  goBack = () => {
    window.history.back();
  };

  render() {
    let { bet, users } = this.props;
    let { betList, loading, betLoading, totalBet } = bet;
    let { totalGame, gameList } = users;
    const { isOpen } = this.state;
    const today = new Date().toISOString().split("T")[0];
    // const navigate = useNavigate();
    const handleNavigate = () => {
      this.props.history.push("/app/bidhistory");
    };

    console.log("betList", betList);

    return (
      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className="bg-gray-200 w-full min-h-screen">
          <div className="flex justify-between place-items-center h-16 items-center w-full border bgcolor rounded-b-3xl">
            <div className="flex justify-start place-items-end px-4 gap-3">
              <IoArrowBackOutline className="text-white h-6 w-6" onClick={this.goBack} />
              <div className="font-semibold text-white cursor-pointer">Bid History</div>
            </div>
            <div className="flex justify-start place-items-center text-white px-2 gap-2">
              <span className="font-semibold cursor-pointer">Filter By</span>
              <span className="relative inline-block">
                <RiFilterFill className="cursor-pointer h-5 w-5" onClick={this.toggleDropdown} />
                {isOpen && (
                  <div className="absolute right-0 mt-2 w-64 bg-white border text-black border-gray-300 rounded-lg shadow-lg z-[100]">
                    <div className="flex justify-end p-2">
                      <IoCloseOutline className="cursor-pointer w-6 h-6" onClick={this.toggleDropdown} />
                    </div>
                    <div className="px-3">
                      <div className="">
                        <label className="">From Date</label>
                        <input
                          type="date"
                          max={today}
                          className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                          name="Date"
                          dateFormat="yyyy-mm-dd"
                          id="datepicker"
                          required
                          value={this.state.startDate}
                          onChange={this.inputChangeStartDate}
                        />
                      </div>
                      <div className="">
                        <label className="">To Date</label>
                        <input
                          type="date"
                          max={today}
                          className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                          name="Date"
                          dateFormat="yyyy-mm-dd"
                          id="datepicker"
                          required
                          value={this.state.endDate}
                          onChange={this.inputChangeEndDate}
                        />
                      </div>
                      <div className="">
                        <label className="">Game Name</label>
                        <select
                          onChange={this.handleChangeSelect}
                          name="sports_id"
                          className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none block"
                        >
                          <option value="">All</option>
                          {gameList && gameList.length > 0
                            ? gameList.map((element, index) => (
                              <option
                                value={element && element._id}
                                className="capitalize "
                              >
                                {element && element.name}
                              </option>
                            ))
                            : null}
                        </select>
                      </div>
                      <div className="col-span-1 flex flex-col justify-end items-end p-4">
                        <label className=""> </label>
                        <button
                          onClick={() => this.getBatHistory()}
                          className="py-1.5 px-4 bgblue text-white text-[0.8125rem] rounded"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </span>
            </div>
          </div>

          {/* <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
          <div className="overflow-hidden overflow-y-auto h-[90vh]">

            <div className="px-4 space-y-2 pt-4 pb-12">
              {betList &&
                betList.length > 0 &&
                betList.map((element, index) => {
                  return (
                    <div className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-300">

                      <div className="bgcolor text-white text-center py-2">
                        <p className="text-sm font-semibold">
                          {moment(new Date(parseInt(element.createdAt)))
                            .utcOffset("+05:30")
                            .format("hh:mm A")}
                          ({element?.marketId?.name || "-"}, {element?.isOpen ? "Open" : "Close"}) </p>
                      </div>


                      <div className="px-4 py-3 space-y-2">

                        <div className="flex justify-center items-center gap-2">
                          <p className="text-sm">Game Name:</p>
                          <p className="text-sm font-medium capitalize textcolor">{element?.gameId?.name}</p>
                        </div>

                        <div className="grid grid-cols-2 text-center gap-2">
                          <div>
                            <p className="text-xs text-slate-600">Point</p>
                            <p className="text-lg font-semibold textcolor">{element?.amount || "-"}</p>
                          </div>
                          <div>
                            <p className="text-xs text-slate-600">Digit</p>
                            <p className="text-lg font-semibold textcolor">{element?.betNumber || "-"}</p>
                          </div>
                        </div>

                        {/* Transaction time */}
                        <div className="flex justify-center items-center text-sm">
                          <span className="text-black">Transaction Time:</span>
                          <span className="text-slate-600 px-2">
                            {moment(new Date(parseInt(element.createdAt)))
                              .utcOffset("+05:30")
                              .format("DD-MM-YYYY (ddd)")}
                          </span>
                        </div>

                        {/* Winning message */}
                        <p className="text-green-600 text-center font-semibold">
                          {element?.betStatus === 0 && <div className="text-yellow-500">Pending</div>}
                          {element?.betStatus === 1 && <div className="text-green-800">YOu Won Rs. +{element?.winAmount}  🤩</div>}
                          {element?.betStatus === 2 && <div className="text-red-700">Better Luck Next Time!!! 😔 -{element?.lossAmount}</div>}
                        </p>
                      </div>
                    </div>

                    // <div className="max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
                    //   <div className="flex justify-between place-items-center p-3">
                    //     <p className="text-gray-600 capitalize text-center text-xs">


                    //       {moment(new Date(parseInt(element.createdAt)))
                    //         .utcOffset("+05:30")
                    //         .format("DD-MM-YYYY hh:mm A")}

                    //     </p>
                    //     <p className="text-gray-600 capitalize text-center text-xs">
                    //     {element?.isOpen ? "Open" : "Close"}
                    //     </p>

                    //   </div>
                    //   <div className="flex flex-col justify-center items-center px-6 ">
                    //     <div className="relative w-12 h-12 ">
                    //       <img
                    //         className="rounded-full border-2 border-pink-500"
                    //         src={
                    //           element?.betStatus === 0
                    //             ? "/pending.png"
                    //             : element?.betStatus === 1
                    //               ? "/trophy.png"
                    //               : element?.betStatus === 2
                    //                 ? "/loss.png"
                    //                 : "path/to/default-image.jpg"
                    //         }
                    //         alt="icon_image"
                    //       />
                    //       <div className="absolute top-6 -left-1 bg-pink-500 rounded-full w-6 h-6 flex items-center justify-center text-white text-xs">
                    //         {this.state.offset + index + 1}
                    //       </div>
                    //     </div>
                    //     <div>
                    //       <h2 className=" font-bold text-gray-800 capitalize text-center">{element?.gameId?.name || "-"}</h2>
                    //       <p className="text-gray-600 capitalize text-center">{element?.marketId?.name || "-"}</p>
                    //     </div>

                    //   </div>

                    //   <div className="mt-6 grid grid-cols-3 text-xs gap-4 p-3">
                    //     <div className="bg-white rounded-lg p-4 shadow-md text-center">
                    //       <h3 className="text-gray-800  font-bold">{element?.betNumber || "-"}</h3>
                    //       <p className="text-[#7E9BA0]">Number</p>
                    //     </div>
                    //     <div className="bg-white rounded-lg p-4 shadow-md text-center">
                    //       <h3 className="text-gray-800 font-bold ">{element?.amount || "-"}</h3>
                    //       <p className="text-[#7E9BA0]">Amount</p>
                    //     </div>
                    //     <div className="bg-white rounded-lg p-4 shadow-md text-center">
                    //       <h3 className="text-gray-800 font-bold ">
                    //         {element?.betStatus === 0 && <span className="text-yellow-500">Pending</span>}
                    //         {element?.betStatus === 1 && <span className="text-green-800">+{element?.winAmount}</span>}
                    //         {element?.betStatus === 2 && <span className="text-red-700">-{element?.lossAmount}</span>}

                    //       </h3>
                    //       <p className="text-[#7E9BA0]">Win/Loss</p>
                    //     </div>

                    //   </div>
                    // </div>

                  );
                })}

              <nav className="flex justify-end mt-4 w-full">
                {totalBet && totalBet > this.state.size ? (
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={totalBet / this.state.size}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-cls"}
                    activeClassName={"active"}
                  />
                ) : null}
              </nav>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet,
  };
}

export default connect(mapStateToProps)(BidHistory);

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IoArrowBackOutline } from 'react-icons/io5'
import { betActions, jackpotAction } from '../../_actions'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import Loader from '../../components/Loader/Loader'

function IndianJackpotBidResult() {
  const selector = useSelector(state => state)
  const dispatch = useDispatch()
  const [offset, setoffset] = useState(0)
  const [size, setsize] = useState(2)
  const [page, setpage] = useState(1)
  const [index, setindex] = useState(0)
  const [fromDate, setfromDate] = useState('')
  const [toDate, settoDate] = useState('')


  useEffect(() => {
    let data = {
      "gameId": "",
      "fromDate": fromDate,
      "toDate": toDate,
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": page,
      "size": size
    }
    dispatch(jackpotAction.getBetsListJackPot(data))
  }, [page, toDate])

  const handleChange = async (e) => {
    if (page !== 1) {
      setpage(1)
    }
    setfromDate(e.target.value)
    settoDate(e.target.value)
  }

  let handlePageClick = (data) => {
    console.log("data  ", data);
    setindex(data.selected)
    let offset = Math.ceil(data.selected * size);
    setoffset(offset)
    setpage(data.selected + 1)
  };
  const goBack = () => {
    window.history.back()
  }
  let { jackpot } = selector ? selector : {};
  let { getJackpotBetList, getJackpotBetTotal, loading } = jackpot ? jackpot : {};
  console.log("getJackpotBetList,getJackpotBetTotal", getJackpotBetList, getJackpotBetTotal)

  return (
    <>
      <Loader loading={loading} />

      <div className='bg-white text-black min-h-screen  overflow-y-auto overflow-x-auto w-full flex-wrap'>
        <div className='h-16 w-full border bgcolor rounded-b-3xl flex justify-start items-center px-4 gap-3'>
          <IoArrowBackOutline className='text-white w-6 h-6 cursor-pointer' onClick={goBack} />
          <div className='font-semibold text-white uppercase'> Jackpot Bid History</div>
        </div>

        <div className='flex justify-start space-x-3 items-center text-sm p-4'>
          <p>Select Date:</p>
          <input type='date' onChange={(e) => handleChange(e)} className='bg-white px-3 py-0.5 uppercase rounded-full appearance-none border border-gray-400 text-sm' />
        </div>

        <div className="p-4 overflow-hidden overflow-y-auto h-[90vh]">
          <div className="">
            <div className="flex flex-col gap-5  h-full ">
              {getJackpotBetList &&
                getJackpotBetList.length > 0 &&
                getJackpotBetList.map((element, index) => {
                  return (

                    <div className="max-w-sm w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden border border-gray-300">

                      <div className="bgcolor text-white text-center py-2">
                        <p className="text-base font-semibold">
                          {moment(new Date(parseInt(element.createdAt)))
                            .utcOffset("+05:30")
                            .format("hh:mm A")}
                          ( {element?.marketId?.name || "-"}, {element?.isOpen ? "Open" : "Close"}) </p>
                      </div>


                      <div className="p-4">

                        <div className="flex justify-center place-items-center gap-4">
                          <p>Game Name:</p>
                          <p className="text-sm capitalize">{element?.gameId?.name}</p>
                        </div>

                        <div className="mt-4 grid grid-cols-2 text-center gap-2">
                          <div>
                            <p className="text-xs text-gray-500">Point</p>
                            <p
                              className="text-lg font-semibold textcolor"
                            >
                              {element?.amount || "-"}
                            </p>
                          </div>
                          <div>
                            <p className="text-xs text-gray-500">Digit</p>
                            <p className="text-lg font-semibold textcolor">{element?.betNumber || "-"}</p>
                          </div>

                        </div>

                        {/* Transaction time */}
                        <p className="text-sm text-gray-700 mt-4">
                          <span className="text-black">Transaction Time:</span><span className="text-gray-400 px-2">

                            {moment(new Date(parseInt(element.createdAt)))
                              .utcOffset("+05:30")
                              .format("DD-MM-YYYY (ddd)")}
                          </span>
                        </p>

                        {/* Winning message */}
                        <p className="text-green-600 text-center mt-4">
                          {element?.betStatus === 0 && <div className="text-yellow-500">Pending</div>}
                          {element?.betStatus === 1 && <div className="text-green-800">YOu Won Rs. +{element?.winAmount}  🤩</div>}
                          {element?.betStatus === 2 && <div className="text-red-700">Better Luck Next Time!!! 😔 -{element?.lossAmount}</div>}
                        </p>
                      </div>
                    </div>
                  );
                })}

              <nav className="flex justify-end mt-4 w-full">
                {getJackpotBetTotal && getJackpotBetTotal > size ? (
                  <ReactPaginate
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={getJackpotBetTotal / size}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    pageClassName={"page-cls"}
                    activeClassName={"active"}
                  />
                ) : null}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default IndianJackpotBidResult

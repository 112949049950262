import React from "react";
import { connect } from "react-redux";
import { betActions, userActions } from "../../_actions";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Sidebar from "../../components/Sidebar/Sidebar";
import { RiFilterFill } from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";
import { IoArrowBackOutline } from "react-icons/io5";

class Ledgers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      isOpen: false,
      index: 0,
      betType: 1,
      size: 10,
      optionRadioName: "MATCHED",
      endDate: "",
      startDate: "",
      sportsId: "",
      limit: "",
      offset: 0,
      pageNo: 1,
      size: 50,
    };
  }

  componentDidMount() {
    // Call the getProfile API
    this.props.dispatch(userActions.getProfile());

    // Initialize game request data
    let gameReqData = {
      keyWord: "",
      pageNo: this.state.pageNo,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: this.state.size,
    };

    // Call the getGamesList API
    this.props.dispatch(userActions.getGamesList(gameReqData));
  }

  componentDidUpdate(prevProps) {
    // Check if the getProfile API has completed and the profile data is available
    if (
      prevProps.users.getProfile !== this.props.users.getProfile &&
      this.props.users.getProfile
    ) {
      const getProfile = this.props.users.getProfile;

      // Once the profile data is available, prepare the object for the second API call
      let obj = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
        userId: getProfile._id,
      };

      // Call the getAllEntriesbyId API with the prepared object
      this.props.dispatch(betActions.getAllEntriesbyId(obj));
    }
  }

  getBatHistory = () => {
    const getProfile = this.props.users.getProfile;



    this.setState({ pageNo: 1 }, () => {
      let obj = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
        userId: getProfile._id,
      };

      this.props.dispatch(betActions.getAllEntriesbyId(obj));
    })

  };

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  };

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };

  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  };

  handlePageClick = (data) => {
    const getProfile = this.props.users.getProfile;
    this.setState({ index: data.selected });

    let offset = Math.ceil(data.selected * this.state.size);

    this.setState({ offset: offset });

    this.setState({ pageNo: data.selected + 1 }, () => {
      let pagination = {
        fromDate: this.state.startDate,
        toDate: this.state.endDate,
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: this.state.pageNo,
        size: this.state.size,
        userId: getProfile._id,
      };

      this.props.dispatch(betActions.getAllEntriesbyId(pagination));
    });
  };
  goBack = () => { window.history.back() };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    let { bet, users } = this.props;
    const { isOpen } = this.state;
    let {
      loading,
      betLoading,
      totalBet,
      tnxTotal,
      tnxList,
      allEntries,
      allEntriesTotal,
    } = bet;
    let { totalGame, gameList } = users;
    const today = new Date().toISOString().split("T")[0];

    console.log(allEntriesTotal);
    return (
      <>
        <div>
          <Loader loading={loading} />
        </div>
        <div className="bg-gray-200 w-full min-h-screen ">
          <div className="flex justify-between place-items-center h-16 items-center w-full border bgcolor rounded-b-3xl">
            <div className=" flex justify-start place-items-end px-4 gap-3">
              <IoArrowBackOutline
                className="text-white mb-1 text-lg"
                onClick={this.goBack}
              />
              <div className="  font-semibold text-white cursor-pointer">
                {" "}
                My statement
              </div>
            </div>
            <div className="flex justify-start place-items-center text-white px-2 gap-2">
              <span className="font-semibold cursor-pointer ">Filter By</span>
              <span className="relative inline-block">
                <RiFilterFill
                  className="cursor-pointer"
                  onClick={this.toggleDropdown}
                />
                {isOpen && (
                  <div className="absolute right-0 mt-2 w-80 bg-white border text-black border-gray-300 rounded-lg shadow-lg z-[100]">
                    <div className=" px-2">
                      <div className='flex justify-end'>
                        <IoCloseOutline className='cursor-pointer' onClick={this.toggleDropdown} />
                      </div>
                      <div className="r">
                        <label className="">From Date</label>
                        <input
                          type="date"
                          max={today}
                          className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                          name="Date"
                          dateFormat="yyyy-mm-dd"
                          id="datepicker"
                          required
                          value={this.state.startDate}
                          onChange={this.inputChangeStartDate}
                        />
                      </div>
                      <div className="r">
                        <label className="">To Date</label>
                        <input
                          type="date"
                          max={today}
                          className=" w-full  border border-black px-3 py-1.5 text-[0.8125rem] bg-transparent rounded focus:outline-none"
                          name="Date"
                          dateFormat="yyyy-mm-dd"
                          id="datepicker"
                          required
                          value={this.state.endDate}
                          onChange={this.inputChangeEndDate}
                        />
                      </div>
                      <div className="col-span-1 flex flex-col justify-end items-end p-4">
                        <label className=""> </label>
                        <button onClick={() => this.getBatHistory()} className="py-1.5 px-4 bgblue text-white text-[0.8125rem] rounded">  Submit</button>
                      </div>
                    </div>
                  </div>
                )}
              </span>
            </div>
          </div>

          <div className=" w-full h-screen overflow-y-auto space-y-4 ">
            <div className=" w-full mx-auto border-2 mt-6 ">
              <div className="">
                <table className="table-auto w-full  border border-black text-[10px]">
                  <thead className="">
                    <tr className="bg-gray-100   border-b border-black">
                      <th className=" py-2 text-left px-2">Description</th>
                      <th className=" py-2 text-left px-2">Date</th>
                      <th className=" py-2 text-left px-2">Credit</th>
                      <th className=" py-2 text-left px-2">Debit</th>
                      <th className=" py-2 text-left px-2">Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allEntries && allEntries.length > 0 ? (
                      allEntries.map((element, index) => (
                        <tr key={index} className="border-t border-black">
                          <td className="px-2 py-2 ">
                            {element?.description || ""}
                          </td>
                          <td className="px-2 py-2  whitespace-nowrap">
                            {/* {new Date(element?.createdAt).toDateString() ||
                                ""} */}
                            {moment(element?.createdAt).format('DD,MM,YYYY')}
                          </td>
                          <td className="px-2 py-2 ">
                            {element?.type === "credit"
                              ? element?.amount
                              : "-"}
                          </td>
                          <td className="px-2 py-2 ">
                            {element?.type === "debit"
                              ? element?.amount
                              : "-"}
                          </td>
                          <td className="px-2 py-2 ">
                            {element?.walletAmount.toFixed(2) || ""}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8} className="px-6 py-2 text-sm text-[#313131] whitespace-nowrap text-center">Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {
                <nav className="relative z-0 flex pb-16 justify-end w-76">
                  {allEntriesTotal && allEntriesTotal > this.state.size ? (
                    <ReactPaginate
                      previousLabel={"Prev"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={allEntriesTotal / this.state.size}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={1}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-cls"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </nav>
              }
            </div>
          </div>

        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;
  return {
    users,
    bet,
  };
}

export default connect(mapStateToProps)(Ledgers);

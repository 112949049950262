import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoArrowBackOutline } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { userService } from "../../_services";
import { alertActions, updateBankDetailsAction } from "../../_actions";
import { userActions } from "../../_actions";
import Loader from "../../components/Loader/Loader";
import { RiAccountPinBoxFill } from "react-icons/ri";
import { MdShareLocation } from "react-icons/md";
import { GiCroissantsPupil } from "react-icons/gi";
import { IoMdWallet } from "react-icons/io";


function BankDetails() {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);

  const bankDetails = selector?.users?.getBankdetails;

  useEffect(() => {
    dispatch(userActions.getClientBankDetail());

    if (bankDetails) {
      setInputFields({
        name: bankDetails.bankName || "",
        accountNumber: bankDetails.accountNo || "",
        ifsc: bankDetails.ifsc || "",
        upiId: bankDetails.upiId,
      });
    }
  }, []);

  const [inputFields, setInputFields] = useState({
    name: "",
    accountNumber: "",
    ifsc: "",
    upiId: "",
  });
  const [errors, setErrors] = useState({});

  const handleBack = () => {
    window.history.back();
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputFields({
      ...inputFields,
      [name]: value,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!inputFields.name.trim()) {
      newErrors.name = "Account Holder Name is required";
    }
    if (!inputFields.accountNumber) {
      newErrors.accountNumber = "Bank Account number is required";
    }
    if (!inputFields.ifsc.trim()) {
      newErrors.ifsc = "IFSC is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const data = {
        bankName: inputFields.name,
        accountNo: inputFields.accountNumber,
        ifsc: inputFields.ifsc,
        upiId: inputFields.upiId,
      };
      dispatch(updateBankDetailsAction.saveClientBankDetail(data));
      dispatch(alertActions.success("Bank details Update successfully"));
    }
  };

  return (
    <>
      <Loader loading={selector?.users?.loading} />

      <div className="bg-white h-screen">

        <div className='h-16 w-full border bgcolor rounded-b-3xl flex justify-between items-center place-items-end px-4 gap-3'>
          <div className='flex justify-start place-items-center gap-2'>
            <IoArrowBackOutline className='text-white mb-1 text-lg cursor-pointer' onClick={handleBack} />
            <div className='font-semibold text-white cursor-pointer'>Update Bank Detail</div>
          </div>
        </div>

        <div className="mt-[15%]">
          <div className="flex flex-col justify-center p-4">
            <div className="w-full p-6 max-w-md bg-white shadow-md rounded-lg border">
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="">
                  <label for="floating_helper" class="text-xs pl-5">Account Holder Name</label>
                  <div className="relative">
                    <div className="absolute top-3 left-0 flex items-center pl-3">
                      <FaUserCircle className="textcolor text-2xl" />
                    </div>
                    <input type="text" name="name" placeholder="Account Holder Name" value={inputFields.name} onChange={handleOnChange} className={`w-full pl-12 py-3 border rounded-full focus:outline-none ${errors.name ? "border-red-500" : "bordercolor"}`}
                    />
                  </div>
                  {errors.name && (
                    <p className="text-red-500 text-xs mt-1 pl-4">{errors.name}</p>
                  )}
                </div>

                <div className="">
                  <label for="floating_helper" class="text-xs pl-5">Account Number</label>
                  <div className="relative">
                    <div className="absolute top-3 left-0 flex items-center pl-3">
                      <RiAccountPinBoxFill className="textcolor text-2xl" />
                    </div>
                    <input type="text" name="accountNumber" placeholder="Bank Account number" value={inputFields.accountNumber} onChange={handleOnChange} className={`w-full pl-12 py-3 border rounded-full focus:outline-none ${errors.accountNumber ? "border-red-500" : "bordercolor"}`} />
                  </div>
                  {errors.accountNumber && (
                    <p className="text-red-500 text-xs mt-1 pl-4">
                      {errors.accountNumber}
                    </p>
                  )}
                </div>

                <div className="">
                  <label for="floating_helper" class="text-xs pl-5">IFSC Code</label>
                  <div className="relative">
                    <div className="absolute top-3 left-0 flex items-center pl-3">
                      <MdShareLocation className="textcolor text-2xl" />
                    </div>
                    <input type="text" name="ifsc" placeholder="IFSC" value={inputFields.ifsc} onChange={handleOnChange} className={`w-full pl-12 py-3 border rounded-full focus:outline-none ${errors.ifsc ? "border-red-500" : "bordercolor"}`} />
                  </div>
                  {errors.ifsc && (
                    <p className="text-red-500 text-xs mt-1 pl-4">{errors.ifsc}</p>
                  )}
                </div>

                <div className="">
                  <label for="floating_helper" class="text-xs pl-5">UPI Number</label>
                  <div className="relative">
                    <div className="absolute top-3 left-0 flex items-center pl-3">
                      <GiCroissantsPupil className="textcolor text-2xl" />
                    </div>
                    <input type="text" name="upiId" placeholder="UPI" value={inputFields.upiId} onChange={handleOnChange} className={`w-full pl-12 py-3 border rounded-full focus:outline-none ${errors.ifsc ? "border-red-500" : "bordercolor"}`} />
                  </div>
                </div>

                <div className="flex justify-center items-center">
                  <button type="submit" className="px-4 py-2 text-white bgcolor rounded-full focus:outline-none">  Save Detail</button>
                </div>

              </form>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}

export default BankDetails;

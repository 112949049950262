import { notificationConstant } from "../_constants";
export function notification(state = {}, action) {
    switch (action.type) {

        case notificationConstant.GET_ALL_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true
            };
        case notificationConstant.GET_ALL_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                getNotificationList: action?.user?.getNotificationListByUserId?.data,

            };
        case notificationConstant.GET_ALL_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };



        default:
            return state
    }
}
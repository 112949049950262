import { starlineConstant } from "../_constants";

export function starline(state = {}, action) {
    switch (action.type) {

        case starlineConstant.GET_STARLINE_BID_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case starlineConstant.GET_STARLINE_BID_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getStarlineBetTotal: action?.user?.getBetsListStarLine?.data?.total,
                getStarlineBetList: action?.user?.getBetsListStarLine?.data?.list,

            };
        case starlineConstant.GET_STARLINE_BID_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };



        case starlineConstant.GET_STARLINE_CHART_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case starlineConstant.GET_STARLINE_CHART_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                // getStarlineChartTotal: action?.user,
                getStarlineChartList: action?.user?.calanderForStarLine?.data,

            };
        case starlineConstant.GET_STARLINE_CHART_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };



        default:
            return state
    }
}
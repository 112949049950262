import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect, useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline, IoCaretBackCircleSharp } from "react-icons/io5";
const HowToPlay = ({ dispatch, props }) => {
  const history = useHistory();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { appSettingData } = users;

  useEffect(() => {
    dispatch(userActions.getAllAppSetting());
  }, [])

  const handleGoBack = () => {
    window.history.back()
  }


  return (
    <>
      <div className='bg-[#EEEEEE] h-screen  w-full '>
        <div className='h-16 w-full border bgcolor rounded-b-3xl flex justify-start items-center place-items-end px-3 space-x-3'>
          <IoArrowBackOutline className='text-white w-6 h-6' onClick={handleGoBack} />
          <div className=' font-semibold text-white cursor-pointer text-center'> How To Play</div>
        </div>
        <div className='flex space-x-1 p-1 h-[90vh]  overflow-y-scroll'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <div className='w-full mt-4'>
            <div className='bg-white p-6 rounded-lg shadow-md'>
              {appSettingData && appSettingData.length > 0 && appSettingData.map((e) => {
                if (e && e.key === "HowToPlay") {
                  return (
                    <div
                      key={e.key}
                      className='prose prose-lg max-w-none text-gray-700 space-y-2 text-sm text-center'
                      dangerouslySetInnerHTML={{ __html: e.value }}
                    />
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(HowToPlay));
import React from "react";
import { connect } from "react-redux";
import { DomainName } from "../../_config/index";
import { userActions } from "../../_actions";
import moment from "moment-timezone";
import RulesNotification from "../../components/RulesNotification/RulesNotification";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BetCloseAlert from "../../components/CloseBetAlert/CloseBetAlert";
import growth from "./growth.png";
import Loader from "../../components/Loader/Loader";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { IoIosCloseCircle } from "react-icons/io";
import Header from "../../components/Header/Header";
import { FaPlayCircle } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import ChartModal from "./Chart Modal/ChartModal";



class DashboardMainPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      notification: false,
      editStake: false,
      timeDifference: null,
      isPastClosingTime: false,
      chartModal: false,
      idForChart: null,
    };
  }

  componentDidMount() {
    let data = {
      keyWord: "",
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 100,
    };

    this.props.dispatch(userActions.getAllSlider());
    this.props.dispatch(userActions.getAllAppSetting());
    this.props.dispatch(userActions.getAllBrandForAdmin(data));
    this.props.dispatch(userActions.getProfile());
    this.intervalId = setInterval(() => {
      this.props.dispatch(userActions.getGamesList(data));
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  navigateGameDetailsPage = (data, elem) => {
    const closingTimeInMillis = data.closingTime;
    const currentTimeInMillis = new Date().getTime();

    if (currentTimeInMillis < closingTimeInMillis) {
      this.props.history.push(
        `/app/matkaDetails/${data.name}/${data._id}/${elem}`,
      );
    } else {
      // this.setState({ isPastClosingTime: true });
      this.props.history.push(
        `/app/matkaDetails/${data.name}/${data._id}/${elem}`,
      );
    }
  };

  handleCloseAlert = () => {
    this.setState({ isPastClosingTime: false });
  };

  handleCloseNotification = () => {
    this.setState({ notification: false });
    localStorage.setItem("hasSeenPopup", true);
  };

  openChartModal = (data) => {
    this.setState(prevState => ({
      chartModal: !prevState.chartModal,
      idForChart: data
    }));

    console.log(data);
  }

  onClose = () => {
    this.setState({ chartModal: false })
  };

  render() {
    const {
      history,
      setOpen,
      open,
      setOpenMobile,
      openMobile,
      openModal,
      setOpenModal,
      setopensizebar,
      opensizebar,
      onClose
    } = this.props;

    const { activeTab, notification, isPastClosingTime } = this.state;
    let { users } = this.props;
    let {
      totalGame,
      loading,
      gameList,
      brandData,
      currentTimeApi,
      appSettingData,
      slideData,
      getProfile,
    } = users;
    let { blockedGames } = getProfile ? getProfile : {};

    function formatSecondsToTime(totalSeconds) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      const timeString = moment()
        .startOf("day")
        .add(hours, "hours")
        .add(minutes, "minutes")
        .add(seconds, "seconds")
        .format("hh:mm A");

      return timeString;
    }

    const convertSecondsToTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      const minutes = Math.floor(seconds / 60);
      seconds %= 60;
      const formattedHours = hours.toString().padStart(2, "0");
      const formattedMinutes = minutes.toString().padStart(2, "0");
      return `${formattedHours}:${formattedMinutes}`;
    };

    const getCurrentTime = () => {
      const now = moment();
      return now.format("hh:mm:A");
    };
    const currentTimeApis = getCurrentTime(currentTimeApi);

    let recentList = [
      {
        name: "Sridevi",
        closingTime: 211331313131,
        openTime: 1131313131312,
      },
    ];

    var settings = {
      button: false,
      dots: true,
      slickPrev: false,
      slickNext: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };


    let blockedGamesIds =
      blockedGames &&
      blockedGames.length > 0 &&
      blockedGames
        .map((e) => e?.games)
        ?.filter((games) => games !== undefined)
        ?.flat(Infinity);





    const handleCall = () => {
      const mobileNumber = appSettingData?.filter((e) => e.key === "MobileNumber")[0]?.value;

      if (mobileNumber) {
        window.location.href = `tel:${mobileNumber}`;
      }
    };
    const handleWhatsaApp = () => {
      const whatsappNumber = appSettingData?.filter((e) => e.key === "WhatsApp")[0]?.value;

      if (whatsappNumber) {
        window.location.href = `whatsapp://send?phone=${whatsappNumber}`;
      }
    }



    return (
      <div className="relative">
        <Header
          history={this.props.history}
          setOpen={setOpen}
          open={open}
          setOpenMobile={setOpenMobile}
          openMobile={openMobile}
          openModal={openModal}
          setOpenModal={setOpenModal}
          setopensizebar={setopensizebar}
          opensizebar={opensizebar}
        />

        <div className="w-full absolute top-20 bg-white rounded-3xl" style={{ backgroundColor: "#fff" }}>
          <Loader loading={loading} />
          <div className="flex space-x-1 rounded-3xl">
            <div className="lg:flex w-full space-x-1">
              <div className="w-full space-y-1 h-screen">
                <div className="overflow-hidden w-full">
                  <div className="main-div">
                    <div>
                      <marquee className="font-semibold mt-1 textcolor text-sm py-2 bgheader">
                        {appSettingData &&
                          appSettingData.length > 0 &&
                          appSettingData.filter(
                            (e) => e.key === "Marquee",
                          )[0]?.value}

                      </marquee>
                    </div>
                    <div className="w-[90%] mx-auto text-white">
                      <Link to={"/app/BrandsList"}>
                        <div className="bgblue rounded-full w-full px-2 py-2">
                          <div className="flex justify-center items-center gap-3 w-full">
                            <FaPlayCircle className="w-6 h-6" />
                            <span className="text-lg whitespace-nowrap px-1 uppercase">Starline</span>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="flex w-[90%] mx-auto text-white my-4 justify-between gap-2">
                      <Link to={"/app/add-fund"}>
                        <div className="bgblue rounded-full px-2 py-2 ">
                          <button className="flex justify-between items-center gap-1">
                            <FaPlayCircle className="w-4 h-4" />
                            <span className="text-sm whitespace-nowrap px-1">Add Money</span>
                          </button>
                        </div>
                      </Link>
                      <Link to={"/app/withdraw-fund"}>
                        <div className="bgblue rounded-full px-2 py-2 ">
                          <button className="flex justify-between items-center gap-1">
                            <FaPlayCircle className="w-4 h-4" />
                            <span className="text-sm whitespace-nowrap px-1">Withdraw Money</span>
                          </button>
                        </div>
                      </Link>
                    </div>

                    <div className="flex flex-row md:flex-row pb-2 justify-between items-center text-black mx-4 rounded-xl space-y-2 md:space-y-0">
                      <div className="rounded-full flex px-2 items-center gap-1 cursor-pointer" onClick={handleCall}>
                        <span>
                          <MdCall className="text-base" />
                        </span>
                        <span className="text-[16px] ">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter(
                              (e) => e.key === "MobileNumber",
                            )[0]?.value}
                        </span>
                      </div>
                      <div className="rounded-full flex px-2 items-center gap-1 cursor-pointer" onClick={handleWhatsaApp}>
                        <FaWhatsapp className="w-5 h-5 text-green-600" />
                        <span className="text-[16px] ">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter(
                              (e) => e.key === "WhatsApp",
                            )[0]?.value}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* <div className=" bgblue">
                    <SimpleSlider slideData={slideData} />
                  </div> */}

                  <div className="space-y-5 h-[73vh] pb-20 overflow-y-auto  pt-5 px-4">
                    {/* <Link
                      to={"/app/BrandsList"}
                      className=" w-full border-4 flex md:pr-12  shadow-orange-500 shadow-md justify-center items-center rounded-lg border-[#F97941] bgblue text-[#F97941]  my-3 p-4 text-2xl  uppercase "
                    >
                      <span className=" "></span>
                      <span
                        style={{ fontFamily: "Algerian" }}
                        className=" flex-1 text-center md:text-[50px] text-lg font-bold"
                      >
                        FataFat Games
                      </span>
                    </Link> */}

                    {gameList && gameList.length > 0
                      ? gameList.map((element, index) => {
                        const openingTime = moment()
                          .hours(parseInt(element && element.openTime / 3600))
                          .minutes(
                            parseInt(
                              (element && element.openTime % 3600) / 60,
                            ),
                          )
                          .seconds(parseInt(element && element.openTime % 60))
                          .milliseconds(0);

                        const closingTime = moment()
                          .hours(
                            parseInt(element && element.closingTime / 3600),
                          )
                          .minutes(
                            parseInt(
                              (element && element.closingTime % 3600) / 60,
                            ),
                          )
                          .seconds(
                            parseInt(element && element.closingTime % 60),
                          )
                          .milliseconds(0);

                        // // --------------------------------------------------------
                        // const closeCloseBidTime = moment
                        //   .duration(element?.betClosingTime, "seconds")
                        //   .asMinutes();
                        // const timebeforeClose = closingTime
                        //   .clone()
                        //   .subtract(closeCloseBidTime, "minutes");
                        // const timebeforeCloseBets =
                        //   timebeforeClose.format("hh:mm A");
                        // // --------------------------------------------------------
                        // // --------------------------------------------------------
                        // const closeOpenBidTimes = moment
                        //   .duration(element?.betOpenTime, "seconds")
                        //   .asMinutes();
                        // const timebeforeOpen = openingTime
                        //   .clone()
                        //   .subtract(closeOpenBidTimes, "minutes");
                        // const timebeforeOpenBets =
                        //   timebeforeOpen.format("hh:mm A");
                        // // --------------------------------------------------------

                        const OpenBetTime = openingTime.format("hh:mm A");
                        const CloseBetTime = closingTime.format("hh:mm A");
                        // // isBefore close
                        // const isBeforeClose = moment(
                        //   currentTimeApis,
                        //   "hh:mm A",
                        // ).isBefore(moment(timebeforeCloseBets, "hh:mm A"));

                        // console.log("elementelement", element);
                        let { isBeforeOpen, isBeforeClose } =
                          element?.timmingManagement;

                        return (
                          <div className="w-full  shadow-md overflow-hidden  border-2 text-[#F97941] rounded-3xl bg-white  ">
                            <div>
                              <div className="flex justify-around place-items-center bgcolor text-white rounded-t-xl  px-4 py-1">
                                <div className="text-[13px]  font-semibold items-center">
                                  Open Time :{" "}
                                  {(OpenBetTime && OpenBetTime) || ""}
                                </div>
                                <div className="text-[13px] font-semibold items-center ">
                                  Close Time :{" "}
                                  {(CloseBetTime && CloseBetTime) || ""}
                                </div>
                              </div>
                              <div className="flex justify-between place-items-center px-4 py-2">
                                <div>
                                  <img src={growth} className=" w-14 h-14 cursor-pointer" alt="chart" onClick={() => this.openChartModal(element)} />
                                </div>

                                <div className="">
                                  <div className="uppercase text-[14px] justify-center items-center flex flex-col  font-bold   pt-2">
                                    <div className="text-black">
                                      {element && element.name
                                        ? element.name
                                        : ""}
                                    </div>
                                    <div className="text-red-600">
                                      <div className="font-semibold">
                                        {element &&
                                          element?.market &&
                                          element?.market?.openNumber?.toString() ==
                                          "0"
                                          ? "000"
                                          : element?.market?.openNumber?.toString() ||
                                          "***"}
                                        -
                                        {(element &&
                                          element?.market &&
                                          element?.market?.jodiNumber &&
                                          element?.market?.jodiNumber.split(
                                            "",
                                          )[0]) ||
                                          "*"}
                                        {(element &&
                                          element?.market &&
                                          element?.market?.jodiNumber?.toString() &&
                                          element?.market?.jodiNumber.split(
                                            "",
                                          )[1]) ||
                                          "*"}
                                        -
                                        {element &&
                                          element?.market &&
                                          element?.market?.closeNumber?.toString() ==
                                          "0"
                                          ? "000"
                                          : element?.market?.closeNumber?.toString() ||
                                          "***"}
                                      </div>
                                    </div>

                                    <div>
                                      {isBeforeClose &&
                                        !element?.market?.closeNumber?.toString() ? (
                                        <div className="w-full text-[#81c098]  uppercase font-bold text-center">
                                          Market Open
                                        </div>
                                      ) : (
                                        <div className="w-full text-[#FE0000] font-bold  capitalize  text-center">
                                          Market Close
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-2">
                                  {
                                    <div>
                                      {isBeforeOpen ? (
                                        <div
                                          onClick={() =>
                                            this.navigateGameDetailsPage(
                                              element,
                                              true,
                                            )
                                          }
                                          className="flex active:scale-90 justify-center my-1 items-center gap-2 px-2 py-1 border-2 border-white bg-green-600 rounded-3xl cursor-pointer hover:bg-green-700 transition duration-300"
                                        >
                                          <FaPlayCircle className="text-white text-base" />
                                          <span className="text-base font-semibold text-white">
                                            Open
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="flex active:scale-90 justify-center my-1 items-center gap-2 px-2 py-1 border-2 border-white bg-red-600 rounded-3xl cursor-pointer hover:bg-red-700 transition duration-300">
                                          <FaPlayCircle className="text-white text-base" />
                                          <span className="text-base font-semibold text-white">
                                            Open
                                          </span>
                                        </div>
                                      )}

                                      {isBeforeClose ? (
                                        <div
                                          onClick={() =>
                                            this.navigateGameDetailsPage(
                                              element,
                                              false,
                                            )
                                          }
                                          className="flex active:scale-90 justify-center my-1 items-center gap-2 px-2 py-1 border-2 border-white bg-green-600 rounded-3xl cursor-pointer hover:bg-green-700 transition duration-300"
                                        >
                                          <IoIosCloseCircle className="text-white text-base" />
                                          <span className="text-base font-semibold text-white">
                                            Close
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="flex active:scale-90 justify-center my-1 items-center gap-2 px-2 py-1 border-2 border-white bg-red-600 rounded-3xl cursor-pointer hover:bg-red-700 transition duration-300">
                                          <IoIosCloseCircle className="text-white text-base" />
                                          <span className="text-base font-semibold text-white">
                                            Close
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.isPastClosingTime ? (
            <BetCloseAlert handleCloseAlert={this.handleCloseAlert} />
          ) : null}

          {
            this.state.chartModal && (
              <ChartModal onClose={this.onClose} idForChart={this.state.idForChart} />
            )
          }

          {notification && (
            <RulesNotification
              handleCloseNotification={this.handleCloseNotification}
              DomainName={DomainName}
            />
          )}
        </div>
        {/* <Footer props={this.props} /> */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(DashboardMainPage);

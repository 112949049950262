import { jackpotChartConstant } from "../_constants";

export function jackpotchart(state = {}, action) {
    switch (action.type) {

        case jackpotChartConstant.GET_JACKPOT_CHART_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case jackpotChartConstant.GET_JACKPOT_CHART_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getjackpotChartList: action?.user?.calanderForJackpot?.data,

            };
        case jackpotChartConstant.GET_JACKPOT_CHART_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };



        default:
            return state
    }
}
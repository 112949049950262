import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';

class DepositeAmount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            file: '',
            imagePreviewUrl: '',
            fieldslogin: {},
            errorslogin: {},
        };
        this.amountInputRef = React.createRef();
    }
    componentDidMount() {
        this.props.dispatch(userActions.clearImage());
    }


    handleFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.props.dispatch(userActions.uploadImage(event)); // Assuming uploadImage is defined in userActions
        } else {
            console.log("No File To Upload!");
        }


        this.setState((prevState) => ({
            errorslogin: {
              ...prevState.errorslogin,
              img: ""  // Clear the error for the amount field
            }
          }));
    };



    submit = (e) => {
        e.preventDefault();
        let { users } = this.props
        let { imgUrl, loading } = users ? users : {}
        // console.log("usersusersusers",users?.getProfile?.parentId        )

        if (this.handleValidationLogin()) {



            const data = {
                amount: this.state.amount,
                image: imgUrl,
                parentId:users?.getProfile?.parentId||null
            }
            // console.log("datadatadata",data)
            this.props.dispatch(userActions.saveClientPayment(data));
            this.setState({
                amount: '',
            });
            if (this.amountInputRef.current) {
                this.amountInputRef.current.value = null;
            }

        }



    }

    handleValidationLogin = () => {
        let { users } = this.props
        let { imgUrl, loading } = users ? users : {}
        let errorslogin = {};
        let formIsValid = true;

        //User Name
        if (!this.state.amount || this.state.amount == "") {
            formIsValid = false;
            errorslogin["amount"] = "Amount  is required";
        }
        if (this.state.amount.includes('.')) {
            formIsValid = false;
            errorslogin["amount"] = "Decimal is not allowed";
          }
        //password
        if (!imgUrl && imgUrl != "") {
            formIsValid = false;
            errorslogin["img"] = "Image  is required";
        }
        this.setState({ errorslogin: errorslogin });
        console.log(errorslogin)
        return formIsValid;
    };





    render() {
        let { users } = this.props
        let { imgUrl, loading } = users ? users : {}
        console.log(imgUrl)
        return (
            <div className='bg-white h-screen '>
                <Loader loading={loading} />
                <form className="max-w-sm mx-auto p-5 ">
                    <div className=' text-xl mb-2 border-b-2'>Deposit Amount</div>
                    <div className="mb-5">
                        <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">Amount</label>
                        <input
                            type="number"

                            id="amount"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#3e44d5] focus:border-[#3e44d5] block w-full p-2.5"
                            placeholder="Amount"
                            required
                            value={this.state.amount} // Example: if you want to capture input value in state
                            onChange={(e) => {
                                const value = e.target.value;
                                this.setState((prevState) => ({
                                    amount: value,
                                    errorslogin: {
                                        ...prevState.errorslogin,
                                        amount: ""  
                                    }
                                }));
                            }}
                        />
                        {this.state.errorslogin && this.state.errorslogin["amount"] ?
                            <div className='  text-red-500 '>{this.state.errorslogin["amount"]}</div> : ""
                        }
                    </div>






                    {imgUrl && <img src={imgUrl} className=' h-10 w-10' />}
                    <div className="mb-5">
                        <label htmlFor="file" className="block mb-2 text-sm font-medium text-gray-900">Upload File</label>
                        <input
                            type="file"
                            ref={this.amountInputRef}
                            id="file"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#3e44d5] focus:border-[#3e44d5] block w-full p-2.5"
                            onChange={(e) => this.handleFile(e)}
                            required
                        />
                               {this.state.errorslogin && this.state.errorslogin["img"] ?
                            <div className='  text-red-500 '>{this.state.errorslogin["img"]}</div> : ""
                        }
                    </div>
                    <button
                        onClick={(e) => this.submit(e)}
                        type="submit"
                        className="text-white bgblue hover:bgblue focus:ring-4 focus:outline-none focus:ring-[#3e44d5] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bgblue dark:hover:bgblue dark:focus:ring-[#3e44d5]"
                    >
                        Submit
                    </button>
                </form>
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { users
    } = state;
    return {
        users

    };
}
export default connect(mapStateToProps)(DepositeAmount);



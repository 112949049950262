import { JackpotConstant } from "../_constants";

export function jackpot(state = {}, action) {
    switch (action.type) {

        case JackpotConstant.GET_JACKPOT_BET_LIST_REQUEST:
            return {
                ...state,
                loading: true
            };
        case JackpotConstant.GET_JACKPOT_BET_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getJackpotBetTotal: action?.user?.getBetsListJackPot?.data?.total,
                getJackpotBetList: action?.user?.getBetsListJackPot?.data?.list,

            };
        case JackpotConstant.GET_JACKPOT_BET_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };



        default:
            return state
    }
}